import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ChartOptions } from "chart.js";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { IChartColor } from "src/app/models/chart.interface";
import { DataChart } from "../../observation-chart/observation-chart.component";

@Component({
  selector: "app-mir-graph-modal",
  templateUrl: "./mir-graph-modal.component.html",
  styleUrls: ["./mir-graph-modal.component.scss"],
})
export class MirGraphModalComponent implements OnInit {
  public chartColors: IChartColor[] = [];
  public chartData: DataChart[];
  public chartLabels: string[];
  public chartOptions: ChartOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      valueArray: number[][];
    }
  ) {}

  ngOnInit(): void {
    this.displayGraph(this.data.valueArray);
  }

  private displayGraph(valueArray: number[][]): void {
    // delete value that x > 0 and y = 0, in this case we don't know if it's a positive or a negative value
    valueArray = valueArray.filter((v) => !(v[0] > 0 && v[1] === 0));
    // add y=0 at max x to connect both curves
    const maxXValue = Math.max(...valueArray.map((v) => v[0])) + 0.1;
    valueArray.push([maxXValue, 0], [maxXValue, 0]);
    // graph options
    this.chartOptions = {
      responsive: true,
      spanGaps: true,
      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback(value) {
                return Number(value).toFixed(1);
              },
            },
          },
        ],
      },
    };
    // x axis
    this.chartLabels = valueArray
      .map((v) => v[0].toString())
      .filter(ArrayHelper.onlyUnique)
      .sort((a, b) => Number(a) - Number(b));

    const positiveValueArray = [];
    const negativeValueArray = [];
    this.chartLabels.forEach((x) => {
      // v[0] = x and v[1] = y
      const values = valueArray.filter((v) => v[0].toString() === x);
      if (values?.length === 2) {
        // we have two values for one x, the first is for the positive curve (exhale) and the second for the negative curve (inhale)
        positiveValueArray.push(values[0][1] >= 0 ? values[0][1] : null);
        negativeValueArray.push(values[1][1] <= 0 ? values[1][1] : null);
      } else {
        // we have only one value for x, check the value to determine in wich curve this is and push null in the other curve
        if (values[0][1] >= 0) {
          positiveValueArray.push(values[0][1]);
          negativeValueArray.push(null);
        } else {
          negativeValueArray.push(values[0][1]);
          positiveValueArray.push(null);
        }
      }
    });
    this.chartData = [];
    if (positiveValueArray?.length) {
      const data = new DataChart();
      data.data = positiveValueArray;
      this.chartData.push(data);
      this.chartColors.push({
        backgroundColor: "#266799",
        borderColor: "#266799",
        pointBackgroundColor: "#266799",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#266799",
      });
    }

    if (negativeValueArray?.length) {
      const data = new DataChart();
      data.data = negativeValueArray;
      this.chartData.push(data);
      this.chartColors.push({
        backgroundColor: "#266799",
        borderColor: "#266799",
        pointBackgroundColor: "#266799",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#266799",
      });
    }
  }
}
