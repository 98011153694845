import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject, forkJoin, of } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import { ArrayHelper } from "src/app/helpers/ArrayHelper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { ICareplan } from "src/app/models/careplans.interface";
import { IQuestionnaire } from "src/app/models/questionnaire.interface";
import { Reference } from "src/app/models/reference.interface";
import { CareplansService } from "src/app/providers/careplans.service";
import { QuestionnairesService } from "src/app/providers/questionnaires.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { AnswerNewQrComponent } from "../answer-new-qr/answer-new-qr.component";

@Component({
  selector: "app-list-of-prac-qr",
  templateUrl: "./list-of-prac-qr.component.html",
  styleUrls: ["./list-of-prac-qr.component.scss"],
})
export class ListOfPracQRComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public questionnaires: IQuestionnaire[] = [];
  public careplans: ICareplan[] = [];
  private onDestroy$ = new Subject<void>();
  public isMobile: boolean;

  constructor(
    private dialog: MatDialog,
    private careplansService: CareplansService,
    private sessionService: SessionService,
    private questionnaireService: QuestionnairesService,
    private readonly dialogRef: MatDialogRef<ListOfPracQRComponent>,
    private responsiveService: ResponsiveService,
    @Inject(MAT_DIALOG_DATA) public data: { patientRef: Reference }
  ) {
    this.responsiveService.isHandset$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.isMobile = value;
    });
  }

  ngOnInit(): void {
    this.careplansService
      .list(this.data.patientRef.reference)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.careplans = response;
        if (this.careplans) {
          const arrId = this.careplans.map((cp) => {
            return cp.support[0].reference;
          });
          const questObs: Observable<IQuestionnaire[]>[] = [];
          arrId.forEach((id) => {
            questObs.push(
              this.questionnaireService.getQuestionnairesByCareplan(id, this.sessionService.userLang, true).pipe(
                catchError((error) => {
                  FileLogger.error("ListOfPracQRComponent", "Error while getting questionnaires of careplan: " + id, error);
                  return of([]);
                })
              )
            );
          });
          forkJoin(questObs)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
              (res) => {
                this.questionnaires = res.reduce((acc, elem) => acc.concat(elem), []).filter(ArrayHelper.onlyUniqueQuestionnaire);
              },
              (err) => {
                this.isLoading = false;
                FileLogger.error("ListOfPracQRComponent", "Error while loading questionnaires: ", err);
              },
              () => {
                this.isLoading = false;
              }
            );
        } else {
          this.isLoading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public openAnswerNewQr(ques: IQuestionnaire): void {
    this.dialog
      .open(AnswerNewQrComponent, {
        data: { questionnaire: ques, patientRef: this.data.patientRef },
        disableClose: true,

        height: this.isMobile ? "100vh" : "80vh",
        width: this.isMobile ? "100vw" : "70vw",
        minWidth: this.isMobile ? "100vw" : "",
        maxWidth: this.isMobile ? "100vw" : "",
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data) {
          this.dialog.closeAll();
        }
      });
  }
}
