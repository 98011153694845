<span #focus></span>
<mat-card class="widget" appDndFiles (fileDropped)="openDocumentModal($event)">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "documents.title" | translate }}</p>
    <a mat-icon-button aria-label="icon button with a ? icon" (click)="openDocumentHelp()">
      <mat-icon>help_outline</mat-icon>
    </a>
    <span class="spacerBetween"></span>
    <app-widget-actions
      *ngIf="actions?.length"
      [actions]="actions"
      [isMobile]="responsiveService.isHandset$ | async"
      (actionTriggered)="handleAction($event.action, $event.event)"
    ></app-widget-actions>

    <input
      type="file"
      style="display: none"
      accept="image/jpg,image/jpeg,image/png,application/pdf,text/plain"
      size="5000000"
      (change)="import($event)"
      #input
    />
  </mat-toolbar>
  <mat-card-content [ngClass]="{ 'mb-0': isMobile }">
    <!-- Disable spinner to avoid buggy effect -->
    <!-- LOADER MANAGEMENT -->
    <!-- <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
    </div> -->

    <!-- TABLE MANAGEMENT -->
    <mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{ "page.document.name" | translate }}
          <app-item-filter
            fieldName="{{ 'page.document.name' | translate }}"
            propertyName="name"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('name')"
            [translateFieldKey]="'page.document.name'"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let doc" (click)="documentClicked(doc.name)">
          <i [class]="contentTypeIcon[doc.contentType]"></i>&nbsp;
          <span>{{ doc.name }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="modified">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ "page.document.modified" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let doc" (click)="documentClicked(doc.name)"
          ><span>{{ doc.modified | date : "dd/MM/yyyy" }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="ownerDisplay">
        <mat-header-cell *matHeaderCellDef>{{ "page.document.ownerDisplay" | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let doc" (click)="documentClicked(doc.name)"
          ><span>{{ doc.ownerDisplay }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="docCategory">
        <mat-header-cell *matHeaderCellDef>{{ "page.document.docCategory.title" | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let doc" (click)="documentClicked(doc.name)"
          ><span>{{ "page.document.docCategory." + doc.docCategory | translate }}</span></mat-cell
        >
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>{{ "table.action" | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.archive' | translate }}"
            *ngIf="!element.archived && ('dashboard/document' | isAuthorized : 'DELETE' | async) === true"
            (click)="archive(element)"
          >
            <mat-icon fontSet="fas" fontIcon="fa-archive"></mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.download' | translate }}"
            *ngIf="('dashboard/document' | isAuthorized | async) === true"
            (click)="download(element)"
          >
            <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="row.archived ? 'archived' : ''"></mat-row>
    </mat-table>

    <p class="text-center pb-1 pt-1" *ngIf="documentsCount === 0">
      {{ "page.document.noDoc" | translate }}
    </p>
  </mat-card-content>
  <mat-card-footer class="m-0">
    <!-- PAGANITOR MANAGEMENT - length = total number of element via a Resolver!!-->
    <mat-paginator [length]="documentsCount" [pageSize]="5" [pageSizeOptions]="[5, 10]" [hidePageSize]="isMobile"></mat-paginator>
  </mat-card-footer>
</mat-card>
