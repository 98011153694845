import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EnableWhenHelper } from "../helpers/enableWhen-helper";
import { Tools } from "../helpers/tools";
import { ICareplan } from "../models/careplans.interface";
import { IObservation, IObservationDefinition, ObservationDefinitionComponent, OComponent } from "../models/observations.interface";
import { IEnableWhen, NameSmiley } from "../models/sharedInterfaces";
import { SessionService } from "../providers/session.service";

@Pipe({ name: "translateObservationNameFromObsDef" })
export class TranslateObservationNameFromObsDefPipe implements PipeTransform {
  constructor(private sessionService: SessionService) {}

  /**
   * Translates the observation name
   * @param observationDef The current observation
   * @returns A translated string
   */
  transform(observationDef: IObservationDefinition): string {
    return Tools.getTranslation(observationDef.nameTranslation, this.sessionService.userLang, observationDef.loinc);
  }
}
@Pipe({ name: "getRange" })
export class GetRangePipe implements PipeTransform {
  public transform(min: number, max: number): number[] {
    const arrRange: number[] = [];
    for (let i = min; i <= max; i++) {
      arrRange.push(i);
    }
    return arrRange;
  }
}
/**
 * We have 11 smileys from 0 to 10. We're matching a value to an smiley :
 *  if value = min
 *      then the smiley is 0 (resp. 10), if showMethod = 1 (resp. -1)
 *  if value = max
 *      then the smiley is 10 (resp. 0), if showMetohd = -1 (resp. 1)
 *  otherwise
 *      the smiley is chosen propotionally at the value, in relation to its position in the range of [min,max]
 */
@Pipe({ name: "rangeSmiley" })
export class RangeSmileyPipe implements PipeTransform {
  public transform(value: number, min: number, max: number, showMethod: number, smiley = NameSmiley.smileyFace): string {
    let nameSmiley = "" + (smiley ?? NameSmiley.smileyFace);
    value = value ? value : min; // Initially, the value is undefined. It is considered to be equal to min
    switch (showMethod) {
      case 1:
        if (smiley === NameSmiley.smileyChild) {
          nameSmiley += Math.round(((value - min) / (max - min)) * 3);
        } else {
          nameSmiley += Math.round(((value - min) / (max - min)) * 10);
        }
        break;
      case -1:
        if (smiley === NameSmiley.smileyChild) {
          nameSmiley += 3 - Math.round(((value - min) / (max - min)) * 3);
        } else {
          nameSmiley += 10 - Math.round(((value - min) / (max - min)) * 10);
        }
        break;
      default:
        break;
    }
    return nameSmiley + ".png";
  }
}

@Pipe({ name: "getLabelRangeFromValue" })
export class GetLabelRangeFromValuePipe implements PipeTransform {
  constructor(protected translateService: TranslateService) {}

  public transform(value: number, componentDefinition: ObservationDefinitionComponent): string {
    // on init, value is not set
    if (!value) {
      value = componentDefinition.min ? componentDefinition.min : 0;
    }
    for (let i = componentDefinition.min; i <= componentDefinition.max; i++) {
      // meaning for value ?
      if (componentDefinition.meaning) {
        for (const meaning of componentDefinition.meaning) {
          if (Number(meaning.value) === value) {
            return Tools.getTranslation(meaning.description, this.translateService.currentLang, String(meaning.value));
          }
        }
      }
    }
    return value.toString();
  }
}
@Pipe({ name: "isObsCompEnabled" })
export class IsObsCompEnabledPipe implements PipeTransform {
  public transform(observation: IObservation, enableWhen: IEnableWhen[], patientCareplans: ICareplan[]): boolean {
    if (!enableWhen || enableWhen.length < 1 || !EnableWhenHelper.isEnableWhen(enableWhen)) {
      return true;
    } else {
      const elemArray = [];
      for (const elem of enableWhen) {
        let value;
        if (!elem.code?.coding?.length) {
          value = this.getResponseFromLoinc(observation, elem.question);
        } else if (elem.code.coding[0].code.startsWith("careplan")) {
          value = this.getStatusFromCareplanAttributes(elem.question, elem.code.coding[0].code, patientCareplans);
        }
        if (!value) {
          return false;
        }
        elemArray.push(EnableWhenHelper.computeIsEnabled(elem, value));
      }
      const trueChecker = (currentValue: boolean) => currentValue === true;
      return elemArray.every(trueChecker);
    }
  }

  private getResponseFromLoinc(observation: IObservation, code: string) {
    const out = observation?.component.find((c) => c.code.coding[0].code === code);
    return out.valueQuantity.value;
  }
  /**
   *
   * @param reference (string) the attribute reference in format: "careplanReference/attributeReference"
   * @param attributeType (string) the type of attribute (activity, addresses...)
   * @returns the status (string) of the attribute
   */
  private getStatusFromCareplanAttributes(reference: string, attributeType: string, allCareplans: ICareplan[]): string | null {
    const splitRef = reference.split("/");
    const careplanRef = splitRef[0];
    const attributeRef = splitRef.slice(1, splitRef.length).join("/");
    const foundCareplan: ICareplan = allCareplans?.find((c) => c.support[0].reference === careplanRef);
    if (!foundCareplan) {
      return null;
    }
    // find the attribute's status:
    switch (attributeType) {
      case "careplan.addresses":
        return foundCareplan.addresses?.find((a) => a.reference === attributeRef)?.status;
      case "careplan.activity":
        return foundCareplan.activity?.find((a) => a.reference.reference === attributeRef)?.detail.status;
      default:
        return null;
    }
  }
}

@Pipe({ name: "getObsComponent" })
export class GetObsComponentPipe implements PipeTransform {
  public transform(code: string, components: OComponent[]): OComponent {
    const foundComp = components.find((c) => c.code.coding[0].code === code);
    return foundComp;
  }
}
