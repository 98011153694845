import { NgModule } from "@angular/core";
import { IVersion } from "../models/preference.interface";
import { SessionService } from "../providers/session.service";

export interface Slide {
  title?: string;
  images: Image[];
  descriptions: string[];
}

export interface Image {
  path?: string;
  height?: string;
  width?: string;
}

export interface ReleaseNote {
  title: string;
  subElements: Slide[];
  releaseId?: IVersion;
}

@NgModule()
export class HelpData {
  constructor(private sessionService: SessionService) {}

  public transformToNumber(version: string): IVersion {
    const array = version.split(".");
    return {
      major: +array[0],
      minor: +array[1],
      patch: +array[2],
    };
  }

  public get globalHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "page.login.cguTitle",
        images: [{ path: "../../../assets/images/cgu.svg", height: "20em" }],
        descriptions: ["page.login.cguText"],
      },
      {
        title: "globalHelp.globalHelpTitle",
        images: [
          {
            path: "../../../assets/images/helpCaptures/helpSlide1" + (lang ? "_" + lang : "_fr") + ".png",
            height: "300px",
          },
        ],
        descriptions: ["globalHelp.slide1"],
      },
      {
        title: "globalHelp.globalHelpTitle",
        images: [
          {
            path: "../../../assets/images/helpCaptures/helpSlide2" + (lang ? "_" + lang : "_fr") + ".png",
            height: "150px",
          },
        ],
        descriptions: ["globalHelp.slide2"],
      },
      {
        title: "globalHelp.globalHelpTitle",
        images: [
          {
            path: "../../../assets/images/helpCaptures/helpSlide3" + (lang ? "_" + lang : "_fr") + ".png",
            height: "400px",
          },
        ],
        descriptions: ["globalHelp.slide3"],
      },
      {
        title: "globalHelp.globalHelpTitle",
        images: [
          {
            path: "../../../assets/images/helpCaptures/helpSlide4-1" + (lang ? "_" + lang : "_fr") + ".png",
            height: "300px",
          },
          {
            path: "../../../assets/images/helpCaptures/helpSlide4-2" + (lang ? "_" + lang : "_fr") + ".png",
          },
        ],
        descriptions: ["globalHelp.slide4"],
      },
      {
        title: "globalHelp.globalHelpTitle",
        images: [
          {
            path: "../../../assets/images/helpCaptures/helpSlide5" + (lang ? "_" + lang : "_fr") + ".png",
          },
        ],
        descriptions: ["globalHelp.slide5"],
      },
    ];
  }

  public patientPageHelp(canDelete: boolean, canDeactivate: boolean, canUpdate: boolean): Slide[] {
    let img2 = "../../../../assets/images/helpCaptures/";
    let text2 = "";
    if (canDelete) {
      img2 = img2 + "patientInfosHelp2_all.png";
      text2 = "patientInfoHelpP2_all";
    } else if (canDeactivate) {
      img2 = img2 + "patientInfosHelp2_noDelete.png";
      text2 = "patientInfoHelpP2_noDelete";
    } else if (canUpdate) {
      img2 = img2 + "patientInfosHelp2_noDeactivate.png";
      text2 = "patientInfoHelpP2_noDeactivate";
    } else {
      img2 = img2 + "patientInfosHelp2_noUpdate.png";
      text2 = "patientInfoHelpP2_noUpdate";
    }
    return [
      {
        title: "globalHelp.patientInfoTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/patientInfosHelp1.jpg",
          },
          { path: img2 },
        ],
        descriptions: ["globalHelp.patientInfoHelpP1", "globalHelp." + text2],
      },
    ];
  }

  public get patientCareplansHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.careplanTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/careplansHelp" + (lang ? "_" + lang : "_fr") + ".png",
            width: "675px",
          },
        ],
        descriptions: ["globalHelp.careplanHelp"],
      },
    ];
  }

  public get patientDrugsHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.drugsTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/drugHelp1" + (lang ? "_" + lang : "_fr") + ".png",
            width: "650px",
          },
          {
            path: "../../../../assets/images/helpCaptures/drugHelp2" + (lang ? "_" + lang : "_fr") + ".png",
            height: "350px",
          },
          {
            path: "../../../../assets/images/helpCaptures/drugHelp3" + (lang ? "_" + lang : "_fr") + ".png",
          },
          {
            path: "../../../../assets/images/helpCaptures/drugHelp4" + (lang ? "_" + lang : "_fr") + ".png",
          },
        ],
        descriptions: ["globalHelp.drugsHelpP1", "globalHelp.drugsHelpP2", "globalHelp.drugsHelpP3", "globalHelp.drugsHelpP4"],
      },
    ];
  }

  public get patientVitalsignsHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.refValueTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/refValueHelp" + (lang ? "_" + lang : "_fr") + ".png",
            width: "675px",
          },
        ],
        descriptions: ["globalHelp.refValueHelp"],
      },
    ];
  }

  public get patientTeleconsultationsHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.videoCallTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/teleconsultationHelp" + (lang ? "_" + lang : "_fr") + ".png",
            width: "675px",
          },
        ],
        descriptions: ["globalHelp.videoCallHelp"],
      },
    ];
  }

  public get patientObservationsHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.observationTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/observationHelp" + (lang ? "_" + lang : "_fr") + ".png",
            width: "675px",
          },
          {
            path: "../../../../assets/images/helpCaptures/observationHelp2" + (lang ? "_" + lang : "_fr") + ".png",
            width: "500px",
          },
          {
            path: "../../../../assets/images/helpCaptures/observationHelp3" + (lang ? "_" + lang : "_fr") + ".png",
            width: "675px",
          },
        ],
        descriptions: ["globalHelp.observationHelp", "globalHelp.observationHelp2", "globalHelp.observationHelp3"],
      },
    ];
  }

  public get patientQRHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.questionnaireTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/patientQRHelp1" + (lang ? "_" + lang : "_fr") + ".png",
            width: "675px",
          },
          {
            path: "../../../../assets/images/helpCaptures/patientQRHelp2" + (lang ? "_" + lang : "_fr") + ".png",
            height: "300px",
          },
        ],
        descriptions: ["globalHelp.questionnaireHelpP1", "globalHelp.questionnaireHelpP2"],
      },
    ];
  }

  public get patientAlertHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.alertTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/patientInfosHelp4" + (lang ? "_" + lang : "_fr") + ".png",
            width: "675px",
          },
        ],
        descriptions: ["globalHelp.alertHelp"],
      },
    ];
  }

  public get teleconsultationsPageHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.videoCallPageTitle",
        images: [
          {
            path: "../../../assets/images/helpCaptures/teleconsultationHelp2" + (lang ? "_" + lang : "_fr") + ".png",
            height: "100px",
          },
        ],
        descriptions: ["globalHelp.videoCallPageHelpP1", "globalHelp.videoCallPageHelpP2"],
      },
    ];
  }

  public get patientListPageHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.patientsPageTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/patientsHelp1" + (lang ? "_" + lang : "_fr") + ".png",
            height: "50px",
          },
          {
            path: "../../../../assets/images/helpCaptures/patientsHelp2" + (lang ? "_" + lang : "_fr") + ".png",
            height: "50px",
          },
          {
            path: "../../../../assets/images/helpCaptures/patientsHelp3.png",
            height: "50px",
          },
          {
            path: "../../../../assets/images/helpCaptures/patientsHelp4" + (lang ? "_" + lang : "_fr") + ".png",
            height: "100px",
          },
        ],
        descriptions: [
          "globalHelp.patientsPageHelpP1",
          "globalHelp.patientsPageHelpP2",
          "globalHelp.patientsPageHelpP3",
          "globalHelp.patientsPageHelpP4",
        ],
      },
    ];
  }

  public get permissionPageHelp(): Slide[] {
    return [
      {
        title: "globalHelp.permissionTitle",
        images: [],
        descriptions: ["globalHelp.permissionPageHelp"],
      },
    ];
  }

  public get userListPageHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.userListPageTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/patientsHelp1" + (lang ? "_" + lang : "_fr") + ".png",
          },
          { path: "../../../../assets/images/helpCaptures/userListHelp1.png" },
        ],
        descriptions: ["globalHelp.userListPageHelpP1", "globalHelp.userListPageHelpP2"],
      },
    ];
  }

  public get patientsOrangeAlert(): Slide[] {
    return [
      {
        title: "globalHelp.orangeAlertTitle",
        images: [],
        descriptions: ["globalHelp.orangeAlert"],
      },
    ];
  }

  public get patientsRedAlert(): Slide[] {
    return [
      {
        title: "globalHelp.redAlertTitle",
        images: [],
        descriptions: ["globalHelp.redAlert"],
      },
    ];
  }

  public get dailyVideocall(): Slide[] {
    return [
      {
        title: "globalHelp.dailyVideocallTitle",
        images: [],
        descriptions: ["globalHelp.dailyVideocall"],
      },
    ];
  }

  public get lastPatients(): Slide[] {
    return [
      {
        title: "globalHelp.lastPatientsTitle",
        images: [],
        descriptions: ["globalHelp.lastPatients"],
      },
    ];
  }

  public get inactifPatients(): Slide[] {
    return [
      {
        title: "globalHelp.inactifPatientsTitle",
        images: [],
        descriptions: ["globalHelp.inactifPatients"],
      },
    ];
  }

  public get patientResearch(): Slide[] {
    return [
      {
        title: "globalHelp.searchPatientHelp",
        images: [],
        descriptions: ["globalHelp.searchPatient"],
      },
    ];
  }

  public get releaseNoteList(): ReleaseNote[] {
    return [
      {
        title: "version-2.1.0-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.1.0A", "version-2.1.0B", "version-2.1.0C"],
          },
        ],
        releaseId: this.transformToNumber("2.1.0"),
      },
      {
        title: "version-2.1.2-title",
        subElements: [
          {
            images: [],
            descriptions: [
              "version-2.1.2A",
              "version-2.1.2B",
              "version-2.1.2C",
              "version-2.1.2D",
              "version-2.1.2E",
              "version-2.1.2F",
              "version-2.1.2G",
              "version-2.1.2H",
              "version-2.1.2I",
            ],
          },
        ],
        releaseId: this.transformToNumber("2.1.2"),
      },
      {
        title: "version-2.2.0-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.2.0A", "version-2.2.0B", "version-2.2.0C", "version-2.2.0D", "version-2.2.0E"],
          },
        ],
        releaseId: this.transformToNumber("2.2.0"),
      },
      {
        title: "version-2.3.0-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.3.0A", "version-2.3.0B", "version-2.3.0C"],
          },
        ],
        releaseId: this.transformToNumber("2.3.0"),
      },
      {
        title: "version-2.3.1-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.3.1A", "version-2.3.1B", "version-2.3.1C", "version-2.3.1D", "version-2.3.1E"],
          },
        ],
        releaseId: this.transformToNumber("2.3.1"),
      },
      {
        title: "version-2.4.0-title",
        subElements: [
          {
            images: [],
            descriptions: [
              "version-2.4.0A",
              "version-2.4.0B",
              "version-2.4.0C",
              "version-2.4.0D",
              "version-2.4.0E",
              "version-2.4.0F",
              "version-2.4.0G",
              "version-2.4.0H",
              "version-2.4.0I",
              "version-2.4.0J",
              "version-2.4.0K",
              "version-2.4.0L",
              "version-2.4.0M",
            ],
          },
        ],
        releaseId: this.transformToNumber("2.4.0"),
      },
      {
        title: "version-2.5.0-title",
        subElements: [
          {
            images: [],
            descriptions: [
              "version-2.5.0A",
              "version-2.5.0B",
              "version-2.5.0C",
              "version-2.5.0E",
              "version-2.5.0F",
              "version-2.5.0G",
              "version-2.5.0H",
              "version-2.5.0I",
            ],
          },
        ],
        releaseId: this.transformToNumber("2.5.0"),
      },
      {
        title: "version-2.5.1-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.5.1A", "version-2.5.1B"],
          },
        ],
        releaseId: this.transformToNumber("2.5.1"),
      },
      {
        title: "version-2.5.2-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.5.2A", "version-2.5.2B", "version-2.5.2C", "version-2.5.2D", "version-2.5.2E"],
          },
        ],
        releaseId: this.transformToNumber("2.5.2"),
      },
      {
        title: "version-2.6.0-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.6.0A", "version-2.6.0B", "version-2.6.0C", "version-2.6.0D"],
          },
        ],
        releaseId: this.transformToNumber("2.6.0"),
      },
      {
        title: "version-2.7.0-title",
        subElements: [
          {
            images: [],
            descriptions: [
              "version-2.7.0A",
              "version-2.7.0B",
              "version-2.7.0C",
              "version-2.7.0D",
              "version-2.7.0E",
              "version-2.7.0F",
              "version-2.7.0G",
              "version-2.7.0H",
            ],
          },
        ],
        releaseId: this.transformToNumber("2.7.0"),
      },
      {
        title: "version-2.8.0-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.8.0A", "version-2.8.0B", "version-2.8.0C", "version-2.8.0D", "version-2.8.0E", "version-2.8.0F"],
          },
        ],
        releaseId: this.transformToNumber("2.8.0"),
      },
      {
        title: "version-2.9.0-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.9.0A", "version-2.9.0B", "version-2.9.0C", "version-2.9.0D"],
          },
        ],
        releaseId: this.transformToNumber("2.9.0"),
      },
      {
        title: "version-2.10.0-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.10.0A", "version-2.10.0B", "version-2.10.0C"],
          },
        ],
        releaseId: this.transformToNumber("2.10.0"),
      },
      {
        title: "version-2.10.1-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.10.1A"],
          },
        ],
        releaseId: this.transformToNumber("2.10.1"),
      },
      {
        title: "version-2.11.0-title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.11.0A", "version-2.11.0B", "version-2.11.0C", "version-2.11.0D"],
          },
        ],
        releaseId: this.transformToNumber("2.11.0"),
      },
      {
        title: "version-2.12.0.title",
        subElements: [
          {
            title: "version-2.12.0.subA.title",
            images: [],
            descriptions: ["version-2.12.0.subA.A", "version-2.12.0.subA.B"],
          },
          {
            title: "version-2.12.0.subB.title",
            images: [],
            descriptions: [
              "version-2.12.0.subB.A",
              "version-2.12.0.subB.B",
              "version-2.12.0.subB.C",
              "version-2.12.0.subB.D",
              "version-2.12.0.subB.E",
              "version-2.12.0.subB.F",
              "version-2.12.0.subB.G",
            ],
          },
          {
            title: "version-2.12.0.subC.title",
            images: [],
            descriptions: ["version-2.12.0.subC.A"],
          },
        ],
        releaseId: this.transformToNumber("2.12.0"),
      },
      {
        title: "version-2.12.1.title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.12.1.A"],
          },
        ],
        releaseId: this.transformToNumber("2.12.1"),
      },
      {
        title: "version-2.13.0.title",
        subElements: [
          {
            title: "version-2.13.0.subA.title",
            images: [],
            descriptions: ["version-2.13.0.subA.A", "version-2.13.0.subA.B", "version-2.13.0.subA.C"],
          },
        ],
        releaseId: this.transformToNumber("2.13.0"),
      },
      {
        title: "version-2.13.2.title",
        subElements: [
          {
            title: "version-2.13.2.subA.title",
            images: [],
            descriptions: ["version-2.13.2.subA.A", "version-2.13.2.subA.B", "version-2.13.2.subA.C", "version-2.13.2.subA.D"],
          },
        ],
        releaseId: this.transformToNumber("2.13.2"),
      },
      {
        title: "version-2.13.3.title",
        subElements: [
          {
            title: "version-2.13.3.subA.title",
            images: [],
            descriptions: ["version-2.13.3.subA.A", "version-2.13.3.subA.B", "version-2.13.3.subA.C", "version-2.13.3.subA.D"],
          },
        ],
        releaseId: this.transformToNumber("2.13.3"),
      },
      {
        title: "version-2.13.4.title",
        subElements: [
          {
            title: "version-2.13.4.subA.title",
            images: [],
            descriptions: ["version-2.13.4.subA.A", "version-2.13.4.subA.B", "version-2.13.4.subA.C"],
          },
        ],
        releaseId: this.transformToNumber("2.13.4"),
      },
      {
        title: "version-2.13.5.title",
        subElements: [
          {
            images: [],
            descriptions: ["version-2.13.5.A"],
          },
        ],
        releaseId: this.transformToNumber("2.13.5"),
      },
    ];
  }

  public get GroupsListPageHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.groupsPageTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/groupsHelp1" + (lang ? "_" + lang : "_fr") + ".png",
            height: "100px",
          },
          {
            path: "../../../../assets/images/helpCaptures/groupsHelp2.png",
            height: "50px",
          },
        ],
        descriptions: ["globalHelp.groupsPageHelpP1", "globalHelp.groupsPageHelpP2"],
      },
    ];
  }

  public get QuestionnairesPageHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.questionnairePageTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/questionnaireHelp1" + (lang ? "_" + lang : "_fr") + ".png",
          },
          {
            path: "../../../../assets/images/helpCaptures/questionnaireHelp2" + (lang ? "_" + lang : "_fr") + ".png",
          },
          {
            path: "../../../../assets/images/helpCaptures/questionnaireHelp3" + (lang ? "_" + lang : "_fr") + ".png",
          },
        ],
        descriptions: ["globalHelp.questionnairePageHelpP1", "globalHelp.questionnairePageHelpP2", "globalHelp.questionnairePageHelpP3"],
      },
    ];
  }

  public get CommunicationPageHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.communicationPageTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/communicationHelp1.png",
          },
          {
            path: "../../../../assets/images/helpCaptures/communicationHelp2" + (lang ? "_" + lang : "_fr") + ".png",
          },
          {
            path: "../../../../assets/images/helpCaptures/communicationHelp3" + (lang ? "_" + lang : "_fr") + ".png",
            height: "100px",
          },
          {
            path: "../../../../assets/images/helpCaptures/communicationHelp2" + (lang ? "_" + lang : "_fr") + ".png",
          },
        ],
        descriptions: [
          "globalHelp.communicationPageP1",
          "globalHelp.communicationPageP2",
          "globalHelp.communicationPageP3",
          "globalHelp.communicationPageP4",
        ],
      },
    ];
  }

  public get patientCommunicationHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.patientCommunicationTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/communicationHelp4" + (lang ? "_" + lang : "_fr") + ".png",
            height: "100px",
          },
        ],
        descriptions: ["globalHelp.patientCommunication"],
      },
    ];
  }

  public get patientRewardHelp(): Slide[] {
    return [
      {
        title: "globalHelp.patientRewardTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/rewardTileHelp1.png",
          },
          {
            path: "../../../../assets/images/helpCaptures/rewardTileHelp2.png",
          },
        ],
        descriptions: ["globalHelp.patientRewardP1", "globalHelp.patientRewardP2"],
      },
    ];
  }

  public get rewardPageHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.rewardPageHelpTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/rewardHelp1" + (lang ? "_" + lang : "_fr") + ".png",
          },
          {
            path: "../../../../assets/images/helpCaptures/rewardHelp2" + (lang ? "_" + lang : "_fr") + ".png",
          },
        ],
        descriptions: ["globalHelp.rewardPageHelpP1", "globalHelp.rewardPageHelpP2"],
      },
    ];
  }

  public get patientsListWidget(): Slide[] {
    return [
      {
        title: "globalHelp.patientsListWidgetTitle",
        images: [],
        descriptions: ["globalHelp.patientsListWidget"],
      },
    ];
  }

  public get knowledgeMediaEditorPageHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.knowledgeMediaEditorSummary",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/mediaEditorSummary1" + (lang ? "_" + lang : "_fr") + ".png",
          },
          {
            path: "../../../../assets/images/helpCaptures/mediaEditorSummary2" + (lang ? "_" + lang : "_fr") + ".png",
          },
          {
            path: "../../../../assets/images/helpCaptures/mediaEditorSummary3" + (lang ? "_" + lang : "_fr") + ".png",
          },
        ],
        descriptions: [
          "globalHelp.mediaEditorSummaryP1",
          "globalHelp.mediaEditorSummaryP2",
          "globalHelp.mediaEditorSummaryP3",
          "globalHelp.mediaEditorSummaryP4",
        ],
      },
    ];
  }

  public get googleAuthHelp(): Slide[] {
    const lang = this.sessionService.userLang;
    return [
      {
        title: "globalHelp.googleAuthTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/undraw_two_factor_authentication.svg",
            width: "512px",
          },
        ],
        descriptions: ["globalHelp.googleAuthDef1", "globalHelp.googleAuthDef2"],
      },
      {
        title: "globalHelp.googleAuthTitleHow",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/googleAuth_0.png",
            width: "128px",
          },
          {
            path: "../../../../assets/images/helpCaptures/googleAuth_1.jpg",
            width: "512px",
          },
        ],
        descriptions: ["globalHelp.googleAuthHelp0", "globalHelp.googleAuthHelp1"],
      },
      {
        title: "globalHelp.googleAuthTitleHow",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/googleAuth_2_" + (lang ? lang : "fr") + ".jpg",
            width: "512px",
          },
        ],
        descriptions: ["globalHelp.googleAuthHelp2"],
      },
      {
        title: "globalHelp.googleAuthTitleHow",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/googleAuth_3_" + (lang ? lang : "fr") + ".jpg",
            width: "512px",
          },
        ],
        descriptions: ["globalHelp.googleAuthHelp3"],
      },
      {
        title: "globalHelp.googleAuthTitleHow",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/googleAuth_4.jpg",
            width: "512px",
          },
        ],
        descriptions: ["globalHelp.googleAuthHelp4"],
      },
    ];
  }

  public get drugSchemaPageHelp(): Slide[] {
    return [
      {
        title: "globalHelp.drugSchemaPageHelpTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/drugSchemaPageHelp_1.png",
          },
          {
            path: "../../../../assets/images/helpCaptures/drugSchemaPageHelp_2.png",
          },
          {
            path: "../../../../assets/images/helpCaptures/drugSchemaPageHelp_3.png",
          },
        ],
        descriptions: ["globalHelp.drugSchemaPageHelpP1", "globalHelp.drugSchemaPageHelpP2", "globalHelp.drugSchemaPageHelpP3"],
      },
    ];
  }

  public get drugStepwiseSchemaPageHelp(): Slide[] {
    return [
      {
        title: "globalHelp.drugStepwiseSchemaPageHelpTitle",
        images: [
          {
            path: "../../../../assets/images/helpCaptures/drugSchemaPageHelp_1.png",
          },
          {
            path: "../../../../assets/images/helpCaptures/drugSchemaPageHelp_2.png",
          },
          {
            path: "../../../../assets/images/helpCaptures/drugSchemaPageHelp_3.png",
          },
        ],
        descriptions: [
          "globalHelp.drugStepwiseSchemaPageHelpP1",
          "globalHelp.drugStepwiseSchemaPageHelpP2",
          "globalHelp.drugSchemaPageHelpP3",
        ],
      },
    ];
  }

  public get patientDocumentHelp(): Slide[] {
    return [
      {
        title: "globalHelp.documentTitle",
        images: [],
        descriptions: ["globalHelp.documentHelp"],
      },
    ];
  }
}
