<span #focus></span>
<mat-card class="widget">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "menu.communications" | translate }}</p>
    <button mat-icon-button aria-label="icon button with a ? icon" (click)="openCommunicationHelp()">
      <mat-icon>help_outline</mat-icon>
    </button>
    <span class="spacerBetween"></span>

    <ng-container *ngIf="!isMobile" [ngTemplateOutlet]="datePickers"></ng-container>

    <app-widget-actions
      *ngIf="actions?.length"
      [actions]="actions"
      [isMobile]="isMobile"
      (actionTriggered)="handleAction($event.action)"
    ></app-widget-actions>
  </mat-toolbar>

  <div class="mini-bar" *ngIf="isMobile">
    <ng-container [ngTemplateOutlet]="datePickers"></ng-container>
  </div>

  <mat-card-content [ngClass]="{ hide: dataSource?.data?.length === 0 }">
    <table
      #MatSortMain="matSort"
      #MatTableMain="matTable"
      mat-table
      matSort
      matSortActive="sendDate"
      matSortDirection="desc"
      class="full-width-table"
    >
      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "model.patient.status" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.status' | translate }}"
            propertyName="openStatusLocal"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('openStatusLocal')"
            translatePrefix="choiceLabel.openedStatus"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row" style="text-align: left">
          <span
            matTooltip="{{ 'communication.draft' | translate }}"
            class="fas fa-lg fa-envelope-open-text primary ml-15"
            *ngIf="row.openStatusLocal === 4"
          ></span>
          <span
            matTooltip="{{ 'communication.notDelivred' | translate }}"
            class="fas fa-lg fa-envelope warn ml-15"
            style="opacity: 0.3"
            *ngIf="row.openStatusLocal === 3"
          ></span>
          <span
            matTooltip="{{ 'communication.notOpen' | translate }}"
            class="fas fa-lg fa-envelope warn ml-15"
            *ngIf="!row.isOpened && row.openStatusLocal === 5"
          ></span>
          <span
            matTooltip="{{ 'communication.open' | translate }}"
            class="fas fa-lg fa-envelope-open warn ml-15"
            style="opacity: 0.3"
            *ngIf="row.isOpened"
          ></span>
        </td>
      </ng-container>

      <!-- sendDate Column -->
      <ng-container matColumnDef="sendDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.sendDate" | translate }}
          <app-item-filter
            fieldName="{{ 'table.sendDate' | translate }}"
            propertyName="sent"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('sent')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.sent | date : "dd/MM/yyyy HH:mm" }}
        </td>
      </ng-container>

      <!-- receivedDate Column -->
      <ng-container matColumnDef="receivedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.receivedDate" | translate }}
          <app-item-filter
            fieldName="{{ 'table.receivedDate' | translate }}"
            propertyName="received"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('received')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.receivedDateStringLocal | translate }}
        </td>
      </ng-container>

      <!-- sendTo Column -->
      <ng-container matColumnDef="sendTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.sendTo" | translate }}
          <app-item-filter
            fieldName="{{ 'table.sendTo' | translate }}"
            propertyName="sendTo"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('sendTo')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.sendTo }}</td>
      </ng-container>

      <!-- Subject Column -->
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.subject" | translate }}
          <app-item-filter
            fieldName="{{ 'table.subject' | translate }}"
            propertyName="topic"
            [dataType]="dataTypeText"
            [defaultValue]="getFilter('topic')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let row">{{ row.topic }}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            matTooltip="{{ 'btn.overview' | translate }}"
            class="custom-icon-w"
            *ngIf="('dashboard/communication' | isAuthorized | async) === true"
            (click)="viewCommunication(row)"
            aria-label="icon button with a loupe icon"
          >
            <mat-icon class="custom-icon-w sub-align">search</mat-icon>
          </button>
          <button
            mat-icon-button
            class="custom-icon-w"
            matTooltip="{{ 'btn.updateCom' | translate }}"
            [matTooltipDisabled]="isAllServices$ | async"
            *ngIf="
              row.isEditable &&
              !row.isGrouped &&
              !(row | isBasedOnCareplan) &&
              ('dashboard/communication' | isAuthorized | async) === true &&
              ('dashboard/communications' | isAuthorized : 'PUT' | async) === true
            "
            (click)="editCommunication(row)"
            aria-label="icon button with a pencil icon"
            [disabled]="(isAllServices$ | async) || (row | isBasedOnCareplan)"
            [ngClass]="{
              warn: (isAllServices$ | async) === false,
              medium: (isAllServices$ | async)
            }"
          >
            <mat-icon
              class="custom-icon-w sub-align"
              matTooltip="{{ 'btn.disabledWithAll' | translate }}"
              [matTooltipDisabled]="(isAllServices$ | async) === false"
            >
              edit
            </mat-icon>
          </button>
          <button
            *ngIf="
              row.isEditable &&
              !row.isGrouped &&
              !(row | isBasedOnCareplan) &&
              ('dashboard/communication' | isAuthorized | async) === true &&
              ('dashboard/communications' | isAuthorized : 'PUT' | async) === true
            "
            mat-icon-button
            class="custom-icon-w"
            matTooltip="{{ 'btn.deleteCom' | translate }}"
            (click)="deleteCommunication(row)"
            aria-label="icon button with a trash icon"
          >
            <mat-icon class="custom-icon-w sub-align fail">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [ngClass]="{ hide: dataSource?.data?.length === 0 }"
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [hidePageSize]="true"
    ></mat-paginator>
  </mat-card-content>
  <div *ngIf="!dataSource || dataSource.loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center pt-05 pb-05" *ngIf="dataSource?.data?.length === 0 && !dataSource.loading">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>

<!-- template  -->

<ng-template #datePickers>
  <form [formGroup]="filterFormTable" style="min-width: 0">
    <div class="datePickerContainer ml-5">
      <mat-form-field class="datePickerField ml-5">
        <input
          cdkFocusInitial
          matInput
          [max]="maxFromDate"
          [matDatepicker]="fromDateTable"
          formControlName="fromDate"
          placeholder="{{ 'itemFilter.fromDate' | translate }}"
        />
        <mat-datepicker-toggle matSuffix [for]="fromDateTable"></mat-datepicker-toggle>
        <mat-datepicker #fromDateTable></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="datePickerField ml-5">
        <input
          matInput
          [min]="minToDate"
          [max]="today"
          [matDatepicker]="toDate"
          formControlName="toDate"
          placeholder="{{ 'itemFilter.toDate' | translate }}"
        />
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</ng-template>
