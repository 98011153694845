<span #focus></span>
<mat-card class="widget" [ngClass]="{ 'big forcePaddingBottom': isBig }">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "careplan.title" | translate }}</p>
    <a mat-icon-button aria-label="icon button with a ? icon" (click)="openCareplansHelp()">
      <mat-icon>help_outline</mat-icon>
    </a>
    <span class="spacerBetween"></span>
    <p *ngIf="nbCareplans === 1 && !isBig">
      {{ currentCareplan.description }}
    </p>
    <form [formGroup]="form" *ngIf="nbCareplans > 1 && !isBig && !isMobile">
      <mat-form-field>
        <mat-select formControlName="currentCp" (selectionChange)="switchCp($event)">
          <mat-optgroup *ngFor="let group of careplanGroups" [label]="group.name">
            <mat-option *ngFor="let careplan of group.careplans" [value]="careplan">
              {{ careplan.description }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </form>

    <app-widget-actions
      *ngIf="actions?.length"
      [actions]="actions"
      [isMobile]="responsiveService.isHandset$ | async"
      (actionTriggered)="handleAction($event.action)"
    ></app-widget-actions>
  </mat-toolbar>
  <div class="mini-bar" *ngIf="isMobile">
    <form [formGroup]="form" *ngIf="nbCareplans > 1 && !isBig">
      <mat-form-field>
        <mat-select formControlName="currentCp" (selectionChange)="switchCp($event)">
          <mat-optgroup *ngFor="let group of careplanGroups" [label]="group.name">
            <mat-option *ngFor="let careplan of group.careplans" [value]="careplan">
              {{ careplan.description }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <!-- ======================== MODE SMALL ===============================-->
  <mat-card-content *ngIf="!isBig && isActiveActivity" class="pt-1">
    <ng-container>
      <h3 class="text-center m-none fs-12">
        {{ getSubTitle(currentCareplan) }}
      </h3>
      <table mat-table [dataSource]="dataSource">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.name" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.reference?.display }}
          </td>
        </ng-container>

        <!-- Start Date Column -->
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.startDate" | translate }}
            <!-- SEE CMATE-1301
            <app-item-filter fieldName="{{ 'date' | translate }}" propertyName="detail.startDate"
              [dataType]="dataTypeDate" [defaultValue]="getFilter('detail.startDate')"
              (applyFilter)="applyFilter($event)">
            </app-item-filter>
          --></th>
          <td mat-cell *matCellDef="let element">
            {{ element | activityDate : "start" }}
          </td>
        </ng-container>

        <!-- End Date Column -->
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.endDate" | translate }}
            <app-item-filter
              fieldName="{{ 'date' | translate }}"
              propertyName="detail.endDate"
              [dataType]="dataTypeDate"
              [defaultValue]="getFilter('detail.endDate')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element | activityDate : "end" }}
          </td>
        </ng-container>

        <!-- Responsable Column -->
        <ng-container matColumnDef="responsable">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.responsable" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ getMainPerformer(element) }}
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.status" | translate }}
            <app-item-filter
              fieldName="{{ 'status' | translate }}"
              propertyName="detail.status"
              [dataType]="dataTypeChoice"
              [data]="dataSource.data"
              [defaultValue]="getFilter('detail.status')"
              (applyFilter)="applyFilter($event)"
            ></app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.detail?.status }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [ngClass]="{ hide: !isActiveActivity }"
        #paginator
        [pageIndex]="0"
        [pageSize]="5"
        [pageSizeOptions]="[5]"
        [hidePageSize]="!isBig"
      >
      </mat-paginator>
    </ng-container>
  </mat-card-content>
  <!-- ======================== MODE BIG ===================================-->
  <mat-card-content *ngIf="isBig">
    <mat-card class="widget-inside" *ngFor="let cp of availableCareplans">
      <mat-toolbar color="primary">
        <p>{{ cp.description }}</p>
      </mat-toolbar>
      <mat-card-content *ngIf="isActiveActivityCareplan(cp)">
        <h3 class="text-center m-none">{{ getSubTitle(cp) }}</h3>
        <table [ngClass]="{ hide: !isActiveActivityCareplan(cp) }" mat-table [dataSource]="getDataSource(cp)">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
              {{ "table.name" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.reference?.display }}
            </td>
          </ng-container>

          <!-- Start Date Column -->
          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ "table.startDate" | translate }}

              <!-- SEE CMATE-1301
              <app-item-filter fieldName="{{ 'date' | translate }}" propertyName="detail.startDate"
                [dataType]="dataTypeDate" [defaultValue]="getFilterCp('detail.startDate', cp)"
                (applyFilter)="applyFilterCp($event, cp)">
              </app-item-filter>
            --></th>
            <td mat-cell *matCellDef="let element">
              {{ element | activityDate : "start" }}
            </td>
          </ng-container>

          <!-- End Date Column -->
          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef>
              {{ "table.endDate" | translate }}
              <!-- SEE CMATE-1301
              <app-item-filter fieldName="{{ 'date' | translate }}" propertyName="detail.endDate"
                [dataType]="dataTypeDate" [defaultValue]="getFilterCp('detail.endDate', cp)"
                (applyFilter)="applyFilterCp($event, cp)">
              </app-item-filter>
            --></th>
            <td mat-cell *matCellDef="let element">
              {{ element | activityDate : "end" }}
            </td>
          </ng-container>

          <!-- Responsable Column -->
          <ng-container matColumnDef="responsable">
            <th mat-header-cell *matHeaderCellDef>
              {{ "table.responsable" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ getMainPerformer(element) }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
              {{ "table.status" | translate }}
              <app-item-filter
                fieldName="{{ 'table.status' | translate }}"
                propertyName="detail.status"
                [dataType]="dataTypeChoice"
                [data]="getCpData('detail.status', cp)"
                [defaultValue]="getFilterCp('detail.status', cp)"
                (applyFilter)="applyFilterCp($event, cp)"
              >
              </app-item-filter>
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.detail?.status }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-card-content>
      <mat-card-content *ngIf="!isActiveActivityCareplan(cp)">
        <p class="text-center">
          {{ "widget.noData" | translate }}
        </p>
      </mat-card-content>
    </mat-card>
    <p class="text-center" *ngIf="isNoData() && !loading">
      {{ "widget.noData" | translate }}
    </p>
  </mat-card-content>
  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center pt-05 pb-05" *ngIf="!isBig && !isActiveActivity && !loading">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
