<table style="width: 100%">
  <thead>
    <tr class="first-level">
      <td rowspan="2">{{ "table.date" | translate }}</td>
      <td *ngFor="let x of tableData.headers" [attr.colspan]="x.lsTimingWhen?.length ? x.lsTimingWhen.length : 1" class="obs-title">
        <div>
          {{
            x?.shortnameTranslation ? x.shortnameTranslation[translateService.currentLang] : x.nameTranslation[translateService.currentLang]
          }}
        </div>
        <ng-container *ngIf="x.components?.length > 1">
          (<span *ngFor="let ct of x.components; let index = index">
            {{
              ct?.shortnameTranslation
                ? ct.shortnameTranslation[translateService.currentLang]
                : ct.nameTranslation[translateService.currentLang]
            }}
            <span *ngIf="index < x.components.length - 1"> / </span> </span
          >)
        </ng-container>
      </td>
    </tr>
    <tr>
      <ng-container *ngFor="let x of tableData.headers">
        <ng-container *ngIf="x.lsTimingWhen?.length">
          <td *ngFor="let moment of x.lsTimingWhen">
            {{ moment.name[translateService.currentLang] }}
          </td>
        </ng-container>

        <ng-container *ngIf="!x.lsTimingWhen?.length">
          <td>&nbsp;</td>
        </ng-container>
      </ng-container>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let row of tableData.row">
      <td>{{ row.date | date : "dd/MM/yyyy" }}</td>
      <ng-container *ngFor="let x of tableData.headers">
        <ng-container *ngIf="x.lsTimingWhen?.length">
          <td *ngFor="let moment of x.lsTimingWhen">
            <div *ngFor="let observation of row.observations | getCurrentObservation : x.loinc : moment.when.code">
              <ng-container *ngFor="let component of observation.component; let i = index">
                <span
                  *ngIf="component.valueQuantity.value !== undefined && component.valueQuantity.value !== null; else no_data"
                  [style]="component.parentObservation?.normColor ? 'color:' + component.parentObservation.normColor : ''"
                >
                  {{
                    component.code?.coding[0].code
                      | loincAndValueMeaning
                        : component.parentObservation?.code?.coding[0].code
                        : component.valueQuantity.value
                        : obsDefinition
                        : true
                        : true
                        : true
                  }}&nbsp;
                </span>
                <ng-template #no_data>{{ "table.nc" | translate }}</ng-template>
                <mat-icon *ngIf="component.valuePictures?.length" class="pictureButton"> camera_alt </mat-icon>
                <span *ngIf="i < observation.component.length - 1"> / </span>
              </ng-container>

              <span *ngFor="let alert of observation | getAlerts">
                <mat-icon
                  *ngIf="alert.rule?.level === RULE_LEVEL.MEDIUM || alert.rule?.level === RULE_LEVEL.HIGH"
                  class="custom-icon-small"
                  [ngClass]="[alert.rule | alertLevelClass, alert.rule?.resultsPractitionner[0]?.value ? 'cursor-help' : '']"
                  [matTooltip]="alert.rule?.resultsPractitionner[0]?.value | getTranslation : ''"
                  >notifications</mat-icon
                ></span
              >
              <span>
                <mat-icon
                  class="ml-05 pointer color-button"
                  style="width: unset; height: unset"
                  fontSet="fas"
                  fontIcon="fa-history"
                  *ngIf="(observation | getOutdatedAlerts).length"
                  [matTooltip]="'dialog.alertsHistory.title' | translate"
                  (click)="openAlertHistory(observation)"
                ></mat-icon
              ></span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="!x.lsTimingWhen?.length">
          <td>
            <div *ngFor="let observation of row.observations | getCurrentObservation : x.loinc">
              <ng-container *ngFor="let component of observation.component; let i = index">
                <span
                  *ngIf="component.valueQuantity.value !== undefined && component.valueQuantity.value !== null; else no_data"
                  [style]="component.parentObservation?.normColor ? 'color:' + component.parentObservation.normColor : ''"
                >
                  {{
                    component.code?.coding[0].code
                      | loincAndValueMeaning
                        : component.parentObservation?.code?.coding[0].code
                        : component.valueQuantity.value
                        : obsDefinition
                        : true
                        : true
                        : true
                  }}&nbsp;
                </span>
                <ng-template #no_data>{{ "table.nc" | translate }}</ng-template>
                <mat-icon
                  *ngIf="component.valuePictures?.length"
                  class="pictureButton"
                  (click)="observationService.showPictures(component, x.nameTranslation[translateService.currentLang], observations)"
                >
                  camera_alt
                </mat-icon>
                <span *ngIf="i < observation.component.length - 1"> / </span>
              </ng-container>

              <span *ngFor="let alert of observation | getAlerts">
                <mat-icon
                  *ngIf="alert.rule?.level === RULE_LEVEL.MEDIUM || alert.rule?.level === RULE_LEVEL.HIGH"
                  class="custom-icon-small"
                  [ngClass]="[alert.rule | alertLevelClass, alert.rule?.resultsPractitionner[0]?.value ? 'cursor-help' : '']"
                  [matTooltip]="alert.rule?.resultsPractitionner[0]?.value | getTranslation : ''"
                  >notifications</mat-icon
                ></span
              >
              <span>
                <mat-icon
                  class="ml-05 pointer color-button"
                  style="width: unset; height: unset"
                  fontSet="fas"
                  fontIcon="fa-history"
                  *ngIf="(observation | getOutdatedAlerts).length"
                  [matTooltip]="'dialog.alertsHistory.title' | translate"
                  (click)="openAlertHistory(observation)"
                ></mat-icon
              ></span>
            </div>
          </td>
        </ng-container>
      </ng-container>
    </tr>
  </tbody>
</table>
