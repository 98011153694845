<div class="close-cross">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="alertDetailsHistoric">
  <mat-card>
    <mat-card-header>
      <h3>
        {{ "alerts.historic" | translate }}
        {{ isLocalMode ? " : " + localDrugName : "" }}
      </h3>
    </mat-card-header>

    <mat-card-content style="padding: 0px !important; overflow: auto" [ngClass]="{ hide: data?.historic?.length === 0 }">
      <table mat-table matSort matSortDirection="desc" matSortActive="date" [dataSource]="dataSource">
        <!-- date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.date" | translate }}
            <app-item-filter
              fieldName="{{ 'table.date' | translate }}"
              propertyName="date"
              [dataType]="dataTypeDate"
              [defaultValue]="getFilter('date')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date : "short" }}
          </td>
        </ng-container>

        <!-- Author Column -->
        <ng-container matColumnDef="author">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matSortDirection="asc">
            {{ "table.author" | translate }}
            <app-item-filter
              fieldName="{{ 'model.patient.name' | translate }}"
              propertyName="author.display"
              [dataType]="dataTypeText"
              [defaultValue]="getFilter('author.display')"
              (applyFilter)="applyFilter($event)"
            >
            </app-item-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.author?.display }}
          </td>
        </ng-container>

        <!-- drugsName Column -->
        <ng-container matColumnDef="drugsName">
          <th mat-header-cell *matHeaderCellDef>
            {{ "mydrugs.name" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ getDrugName(element.entityId) || element.entityName }}
          </td>
        </ng-container>

        <!-- value type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>
            {{ "table.actionP" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <p *ngIf="isNotUpdateHistoric(element)" class="font-12">
              {{ getType(element) }}
            </p>
            <ul *ngIf="!isNotUpdateHistoric(element)" class="no-bullet font-12">
              <li>{{ "actionDrugsHistoric.updates" | translate }}</li>
              <li *ngFor="let item of getTypeArray(element)">{{ item }}</li>
            </ul>
          </td>
        </ng-container>

        <!-- exValue type Column -->
        <ng-container matColumnDef="exValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.exValue" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <p *ngIf="isNotUpdateHistoric(element)" class="font-12">N.A.</p>
            <ul *ngIf="!isNotUpdateHistoric(element)" class="no-bullet font-12">
              <br />
              <li *ngFor="let item of getExValuesArray(element)">{{ item }}</li>
            </ul>
          </td>
        </ng-container>

        <!-- newValue type Column -->
        <ng-container matColumnDef="newValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "table.newValue" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <p *ngIf="isNotUpdateHistoric(element)" class="font-12">N.A.</p>
            <ul *ngIf="!isNotUpdateHistoric(element)" class="no-bullet font-12">
              <br />
              <li *ngFor="let item of getNewValuesArray(element)">
                {{ item || "N.A" }}
              </li>
            </ul>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        style="width: 400px"
        class="borderBox"
        #paginator
        [pageIndex]="0"
        [pageSize]="5"
        [pageSizeOptions]="[5, 10, 15]"
        [hidePageSize]="false"
      >
      </mat-paginator>
    </mat-card-content>
    <p class="text-center" *ngIf="data?.historic?.length === 0">
      {{ "widget.noData" | translate }}
    </p>
  </mat-card>
</div>
