import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IExternalRessource } from "src/app/models/externalResource.interface";
import { IObservation, IObservationDefinition } from "src/app/models/observations.interface";
import { IObservationParam, IObservationParamWithObsDefinition } from "src/app/models/patientConfig.interface";
import { IStreamGraphLine } from "src/app/models/stream-observation-interface";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class ObservationApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Observations", // Theme !
      ["dashboard/observationDef"], // Post route paths
      [
        "observations", // 0
        "dashboard/observationsDef", // 1
        "dashboard/observationsParam", // 2
        "dashboard/observationsByQR", // 3
        "dashboard/patientStreamObservationsLoincs", // 4
        "dashboard/onlineDeviceData", // 5
        "dashboard/exportObservations", // 6
        "dashboard/onlineDeviceExternalResources", // 7
      ], // Get route paths
      [], // Update route paths
      [] // Delete route paths
    );
  }

  public list(patientId: string, fromDate?: string, toDate?: string, computeNorms?: boolean): Observable<IObservation[]> {
    return this.api.get(this.readRoutes[0], {
      identifier: patientId,
      fromDate,
      toDate,
      computeNorms,
    }) as Observable<IObservation[]>;
  }

  public listDef(caremateId?: string, loincs?: string[]): Observable<IObservationDefinition[]> {
    return this.api.get(this.readRoutes[1], { caremateId, observationsLoincs: loincs?.join(",") }) as Observable<IObservationDefinition[]>;
  }

  public listStream(patientId: string, loincs?: string[]): Observable<string[]> {
    return this.api.get(this.readRoutes[4], { patientId, loincs: loincs?.join(",") }) as Observable<string[]>;
  }

  public listParams(includeObsDef?: boolean): Observable<IObservationParamWithObsDefinition[]> {
    return this.api.get(this.readRoutes[2], { includeObsDef }) as Observable<IObservationParamWithObsDefinition[]>;
  }

  /**
   *
   * @param patientId caremateId of the patient
   * @param startDate start date of the recordings we want
   * @param endDate end date of the recordings we want (if not defined, we sent back data only for one day)
   * @param externalRessourceRef reference of the corresponding externalRessource
   * @param loinc code loinc of the observation we want (in case the device record several observations)
   * @param componentLoincs codes loinc of the components we want. Separated by a comma
   * @returns
   */
  public listOnlineDeviceData(
    patientId: string,
    startDate: string,
    endDate: string,
    externalRessourceRef: string,
    loinc: string,
    componentLoincs?: string[]
  ): Observable<IStreamGraphLine[]> {
    return this.api.get(this.readRoutes[5], { patientId, startDate, endDate, externalRessourceRef, loinc, componentLoincs }) as Observable<
      IStreamGraphLine[]
    >;
  }
  /**
   *
   * @param patientId caremateId of the patient
   * @param loinc (optional) filter external resources by code loinc
   * @returns
   */
  public listStreamObservationsDevices(patientId: string, loinc?: string): Observable<IExternalRessource[]> {
    return this.api.get(this.readRoutes[7], { patientId, loinc }) as Observable<IExternalRessource[]>;
  }

  /**
   * Creates a new observation definition and parameter
   * @param obsDef The new observation definition to save as an ObservationDefinition
   * @param obsParam The new observation parameter to save as an IObservationParam
   * @returns The saved observation definition
   */
  public createObservationDef(obsDef: IObservationDefinition, obsParam: IObservationParam): Observable<IObservationDefinition> {
    const body = {
      observationDef: obsDef,
      observationParam: obsParam,
    };
    return this.api.post(this.createRoutes[0], body) as Observable<IObservationDefinition>;
  }

  /**
   * Returns an observation list (IObservation) from a linked questionnaireResponse identifier
   * @param questionnaireResponseRef
   * @returns
   */
  public getObservationsByQR(questionnaireResponseRef: string): Observable<IObservation[]> {
    return this.api.get(this.readRoutes[3], {
      questionnaireResponseRef,
    }) as Observable<IObservation[]>;
  }

  public exportObservations(
    patientId: string,
    startDate: string,
    endDate: string,
    obsCodes: string[],
    lang: string,
    docType: string
  ): Observable<Blob> {
    return this.api.getBlob(this.readRoutes[6], {
      patientId,
      startDate,
      endDate,
      obsCodes: obsCodes.toString(),
      lang,
      docType,
    }) as Observable<Blob>;
  }
}
