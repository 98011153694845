<div *ngIf="isMonitoringUser && availableMonitOrgs?.length > 1 && currentMonitOrg">
  <mat-form-field appearance="none" id="selectMonitoringOrganization">
    <mat-select
      #monitOrgSelect
      [compareWith]="compareReference"
      (selectionChange)="changeMonitOrganization($event.value)"
      [value]="currentMonitOrg"
      [aria-label]="'Select monitoring organization'"
    >
      <mat-option *ngIf="availableMonitOrgs.length >= 2" [value]="sessionService.allsReference">
        <span id="monitOrgAll">{{ "page.preference.all" | translate }}</span>
      </mat-option>
      <mat-option *ngFor="let orga of availableMonitOrgs; let i = index" [value]="orga">
        <span attr.id="monitOrg{{ i }}">{{ orga.display }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="isMonitoringUser && availableMonitServices?.length > 1 && currentMonitService">
  <mat-form-field appearance="none" id="selectMonitoringService">
    <mat-select
      #monitServiceSelect
      [compareWith]="compareReference"
      (selectionChange)="changeMonitService($event.value)"
      [value]="currentMonitService"
      [aria-label]="'Select monitoring service'"
    >
      <mat-option *ngIf="availableMonitServices.length >= 2" [value]="sessionService.allsReference">
        <span id="monitServAll">{{ "page.preference.all" | translate }}</span>
      </mat-option>
      <mat-option *ngFor="let service of availableMonitServices; let i = index" [value]="service">
        <span attr.id="monitServ{{ i }}">{{ service.display }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="availableOrganizations?.length > 1 && currentOrganization">
  <mat-form-field appearance="none" id="selectOrganization">
    <mat-select
      #orgSelect
      [compareWith]="compareReference"
      (selectionChange)="changeOrganization($event.value)"
      [value]="currentOrganization"
      [aria-label]="'Select organization'"
    >
      <mat-option *ngIf="availableOrganizations.length >= 2" [value]="sessionService.allsReference">
        <span id="orgAll">{{ "page.preference.all" | translate }}</span>
      </mat-option>
      <mat-option *ngFor="let orga of availableOrganizations; let i = index" [value]="orga">
        <span attr.id="org{{ i }}">{{ orga.display }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="currentService && (user?.healthcareService?.length > 1 || availableServices?.length > 1)">
  <mat-form-field appearance="none" id="selectService">
    <mat-select
      #serviceSelect
      [compareWith]="compareReference"
      (selectionChange)="changeService($event.value)"
      [value]="currentService"
      [aria-label]="'Select service'"
    >
      <mat-option *ngIf="availableServices.length >= 2" [value]="sessionService.allsReference">
        <span id="servAll">{{ "page.preference.all" | translate }}</span>
      </mat-option>
      <mat-option *ngFor="let service of availableServices; let i = index" [value]="service">
        <span attr.id="serv{{ i }}">{{ service.display }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="search-patient" *ngIf="isAuthorizeDisplayPatients">
  <mat-label class="search-patient-label"><span class="fa fa-search"></span></mat-label>
  <mat-form-field appearance="none">
    <input
      class="fa fa-search search-patient"
      type="search"
      floatLabel="none"
      placeholder="{{ 'toolbar.searchPatient' | translate }}"
      matInput
      [value]="searchPatient"
      (input)="updateSearch($event.target.value)"
      autocomplete="off"
    />
    <button class="clearButton" *ngIf="searchPatient" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="updateSearch('')">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </mat-form-field>
  <a mat-icon-button class="help-button" aria-label="icon button with a ? icon" (click)="openSearchPatientHelp()">
    <mat-icon class="help-icon" matTooltip="{{ 'globalHelp.searchPatientHelp' | translate }}">help_outline</mat-icon>
  </a>
</div>

<mat-card
  class="result-search-patient"
  [ngClass]="isProd ? 'result-search-patient-top-prod' : 'result-search-patient-top-dev'"
  *ngIf="searchPatientDisplayed"
>
  <table mat-table [dataSource]="patientsInfosDataSource" class="full-width-table">
    <!-- Fullname Column -->
    <ng-container matColumnDef="fullname">
      <th mat-header-cell *matHeaderCellDef>
        {{ "model.patient.name" | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        matTooltip="{{ row.fullname + (row.pseudoname ? ' (' + row.pseudoname + ')' : '') }}"
        matTooltipClass="truncatedNamesTooltip"
      >
        <span>{{ row.fullname + (row.pseudoname ? " (" + row.pseudoname + ")" : "") | truncate : 30 }}</span>
      </td>
    </ng-container>

    <!-- email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        {{ "model.patient.email" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span>{{ row.email }}</span>
      </td>
    </ng-container>

    <!-- phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>
        {{ "model.patient.phone" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span>{{ row.phone }}</span>
      </td>
    </ng-container>

    <!-- ssin Column -->
    <ng-container matColumnDef="ssin">
      <th mat-header-cell *matHeaderCellDef>
        {{ "model.patient.nationalNumber" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span>{{ row.ssin }}</span>
      </td>
    </ng-container>

    <!-- birthdate Column -->
    <ng-container matColumnDef="birthDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ "model.patient.birthdate" | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span>{{ row.birthDate | date : "dd/MM/yyyy" }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="patientRowClicked(row)"></tr>
  </table>
</mat-card>
