<ng-container *ngIf="knowledgeCriteria">
  <h3>{{ "knowledgebase.knowledgeCriteria.title" | translate }}</h3>
  <!-- FIRST LEVEL CRITERIA -->

  <div class="criteria-container criteria-container-knowledge">
    <h3>{{ "knowledgebase.knowledgeCriteria.whenTitle" | translate }}</h3>
    <!-- WHEN -->
    <app-when-input
      [hasSelectedActivity]="!!activityReference"
      [(period)]="knowledgeCriteria.when.period"
      [(periodUnit)]="knowledgeCriteria.when.periodUnits"
      [(showDirectly)]="knowledgeCriteria.showDirectly"
    >
    </app-when-input>

    <app-enable-when-input
      *ngFor="let enableWhen; let index = index; of: knowledgeCriteria?.enableWhen"
      [vitalSignsDefinitions]="availableVS4FirstLevel"
      [enableWhen]="knowledgeCriteria.enableWhen[index]"
      (enableWhenChange)="knowledgeCriteria.enableWhen[index] = $event; setAvailableVS($event); checkInsurance(); checkIfValid()"
      [(enableWhenBehaviour)]="knowledgeCriteria.enableWhenBehavior"
      [index]="index"
      (delete)="removeEnableWhen(knowledgeCriteria, index)"
      [cpActivity]="this.currentActivity"
    >
    </app-enable-when-input>

    <div class="btn-container">
      <p>
        {{ "knowledgebase.knowledgeCriteria.addConditionOn" | translate }}
      </p>
      <button mat-stroked-button (click)="addEnableWhen(knowledgeCriteria)">
        {{ "knowledgebase.knowledgeCriteria.vitalSigns" | translate }}
      </button>
      <div
        [matTooltip]="
          knowledgeCriteria.hasInsuranceCriteria
            ? ('knowledgebase.knowledgeCriteria.insuranceAlreadyAdded' | translate)
            : ('knowledgebase.knowledgeCriteria.insurances' | translate)
        "
      >
        <button
          mat-stroked-button
          (click)="addEnableWhen(knowledgeCriteria, ENABLE_WHEN_TYPE.INSURANCE)"
          [disabled]="knowledgeCriteria.hasInsuranceCriteria"
        >
          {{ "knowledgebase.knowledgeCriteria.insurances" | translate }}
        </button>
      </div>
      <button mat-stroked-button *ngIf="!!activityReference" (click)="addEnableWhen(knowledgeCriteria, ENABLE_WHEN_TYPE.ACTIVITY_FIELDS)">
        {{ "knowledgebase.knowledgeCriteria.activityFields" | translate }}
      </button>
    </div>
  </div>

  <!-- SECOND LEVEL CRITERIA -->

  <div class="criteria-container criteria-container-media" *ngFor="let criteria; let index = index; of: knowledgeCriteria?.subCriteria">
    <div class="row">
      <h3>
        {{ "knowledgebase.knowledgeCriteria.whenMediaTitle" | translate }}
      </h3>
      <!-- MEDIA SELECTOR -->
      <mat-form-field>
        <mat-label>{{ "knowledgebase.knowledgeCriteria.media" | translate }}</mat-label>
        <mat-select [(value)]="criteria.identifier.value" (selectionChange)="checkIfValid()" [disabled]="criteria.identifier.value">
          <mat-option *ngFor="let media of availableMedia[index]" [value]="media.identifier.value">
            {{ media.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- ACTIVITY SELECTOR -->
      <mat-form-field *ngIf="!knowledgeCriteria?.activityReference">
        <mat-label>{{ "knowledgebase.knowledgeCriteria.activities" | translate }}</mat-label>
        <mat-select [(value)]="criteria.activityReference" [compareWith]="compareReference">
          <mat-option [value]="null">
            {{ "knowledgebase.knowledgeCriteria.all" | translate }}
          </mat-option>
          <mat-option *ngFor="let activity of availableActivities.controls" [value]="activity.value.reference | formReferenceToReference">
            {{ activity | getActivityName : currentLanguage }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="spacer"></div>
      <div [matTooltip]="'common.delete' | translate">
        <button mat-icon-button (click)="removeSubCriteria(index)">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>

    <!-- WHEN -->
    <app-when-input
      [hasSelectedActivity]="!!criteria.activityReference"
      [isDisabled]="!knowledgeCriteria.showDirectly"
      [(period)]="criteria.when.period"
      [(periodUnit)]="criteria.when.periodUnits"
      [(showDirectly)]="criteria.showDirectly"
    >
    </app-when-input>

    <app-enable-when-input
      *ngFor="let enableWhen; let index = index; of: criteria?.enableWhen"
      [isSubCriteria]="true"
      [vitalSignsDefinitions]="availableVS4SecondLevel"
      [enableWhen]="criteria.enableWhen[index]"
      (enableWhenChange)="criteria.enableWhen[index] = $event; setAvailableVS($event); checkInsurance(); checkIfValid()"
      [(enableWhenBehaviour)]="criteria.enableWhenBehavior"
      [index]="index"
      (delete)="removeEnableWhen(criteria, index); checkIfValid()"
    >
    </app-enable-when-input>

    <div class="btn-container">
      <p>
        {{ "knowledgebase.knowledgeCriteria.addConditionOn" | translate }}
      </p>
      <button mat-stroked-button (click)="addEnableWhen(criteria)">
        {{ "knowledgebase.knowledgeCriteria.vitalSigns" | translate }}
      </button>
      <div
        [matTooltip]="
          criteria.hasInsuranceCriteria
            ? ('knowledgebase.knowledgeCriteria.insuranceAlreadyAdded' | translate)
            : ('knowledgebase.knowledgeCriteria.insurances' | translate)
        "
      >
        <button mat-stroked-button (click)="addEnableWhen(criteria, ENABLE_WHEN_TYPE.INSURANCE)" [disabled]="criteria.hasInsuranceCriteria">
          {{ "knowledgebase.knowledgeCriteria.insurances" | translate }}
        </button>
      </div>
    </div>
  </div>

  <button
    mat-stroked-button
    (click)="addSubCriteria()"
    [disabled]="
      knowledgeCriteria.subCriteria.length >= 1 &&
      !knowledgeCriteria.subCriteria[knowledgeCriteria.subCriteria.length - 1]?.identifier.value
    "
  >
    {{ "knowledgebase.knowledgeCriteria.addMedia" | translate }}
  </button>

  <div class="mt-1">
    <button class="mr-05" mat-stroked-button (click)="reset()" [matTooltip]="'knowledgebase.knowledgeCriteria.reset' | translate">
      {{ "btn.cancel" | translate }}
    </button>

    <button
      mat-flat-button
      color="primary"
      [matTooltip]="'knowledgebase.knowledgeCriteria.save' | translate"
      (click)="saveCriteria()"
      [disabled]="!isValid"
    >
      {{ "knowledgebase.knowledgeCriteria.save" | translate }}
    </button>
  </div>
</ng-container>

<mat-spinner *ngIf="!knowledgeCriteria" style="margin: auto"></mat-spinner>
