<div class="grid-container">
  <!-- The columns showing the hours (called headerCells)-->
  <!-- Show numbers only if at least one panel is open -->
  <ng-container *ngIf="!areAllClosed">
    <div *ngFor="let value of headerCells; let i = index" class="header-cell" [ngClass]="i === 25 ? 'last' : ''">
      <div *ngIf="i > 0" class="number">{{ i - 1 }}</div>
      <div *ngIf="i > 0" class="lines"></div>
    </div>
  </ng-container>
  <!-- Loop through Days  -->
  <ng-container *ngFor="let dayObs of obsByDay; let i = index">
    <div class="day-header" (click)="toggleVisibility(dayObs)">
      <div>{{ dayObs.date | date : "EEEE dd/MM/yyyy" | titlecase }}</div>
      <mat-icon> {{ dayObs.hidden ? "keyboard_arrow_down" : "keyboard_arrow_up" }}</mat-icon>
    </div>
    <ng-container *ngIf="!dayObs.hidden">
      <!-- Loop through the obsDef of the current day -->
      <ng-container *ngFor="let obsDef of obsDefinitions | filterObsDefinitions : dayObs.observations">
        <div class="observation-title">
          {{ obsDef.nameTranslation[translateService.currentLang] }}
        </div>

        <!-- For loop through observations of the current day -->
        <ng-container *ngFor="let componentDef of obsDef.components">
          <div class="cell component-title">
            {{ componentDef | getShortname }} {{ componentDef.unit ? "(" + componentDef.unit + ")" : "" }}
          </div>

          <div *ngFor="let hour of hours; let i = index" class="cell" [ngClass]="i === 24 ? 'last' : ''">
            <div *ngFor="let comp of hour | getComponentsByHours : dayObs.observations : componentDef.loinc">
              <!-- Value -->
              <span
                *ngIf="!comp.valueQuantity?.valueArray?.length"
                [style]="comp.parentObservation?.normColor ? 'color:' + comp.parentObservation.normColor : ''"
              >
                {{
                  comp.code.coding[0].code
                    | loincAndValueMeaning
                      : comp.parentObservation?.code.coding[0].code
                      : comp.valueQuantity.value
                      : obsDefinitions
                      : true
                      : true
                      : true
                }}
              </span>
              <span *ngIf="comp.valueQuantity?.valueArray?.length">
                <button mat-stroked-button type="button" (click)="showGraph(comp)">
                  {{ "knowledgebase.knowledgeCriteria.show" | translate }}
                </button>
              </span>
              <!-- Moment (when necessary) -->
              <span
                *ngIf="
                  comp.effectiveTiming?.repeat?.when?.code && comp.valueQuantity.value !== null && comp.valueQuantity.value !== undefined
                "
              >
                ({{
                  comp.parentObservation.effectiveTiming.repeat.when.code
                    | getEffectiveTiming : (obsDefinitions | findObsDefByLoinc : comp.parentObservation.code)
                    | getTranslation : ""
                }})
              </span>

              <!-- Picture icon (when necessary) -->
              <mat-icon
                *ngIf="comp.valuePictures?.length"
                class="pictureButton"
                (click)="observationService.showPictures(comp, obsDef.nameTranslation[translateService.currentLang], observations)"
              >
                camera_alt
              </mat-icon>
              <span *ngFor="let alert of comp.parentObservation | getAlerts">
                <mat-icon
                  *ngIf="alert.rule?.level === RULE_LEVEL.MEDIUM || alert.rule?.level === RULE_LEVEL.HIGH"
                  class="custom-icon-small"
                  [ngClass]="[alert.rule | alertLevelClass, alert.rule?.resultsPractitionner[0]?.value ? 'cursor-help' : '']"
                  [matTooltip]="alert.rule?.resultsPractitionner[0]?.value | getTranslation : ''"
                  >notifications</mat-icon
                ></span
              >
              <span>
                <mat-icon
                  class="ml-05 pointer color-button"
                  style="width: unset; height: unset"
                  fontSet="fas"
                  fontIcon="fa-history"
                  *ngIf="(comp.parentObservation | getOutdatedAlerts).length"
                  [matTooltip]="'dialog.alertsHistory.title' | translate"
                  (click)="openAlertHistory(comp.parentObservation)"
                ></mat-icon
              ></span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
