<div class="row">
  <p *ngIf="index === 0">
    {{ "knowledgebase.knowledgeCriteria.onlyIf" | translate }}
  </p>

  <!-- Show AND or OR when more than one input -->
  <mat-form-field *ngIf="index > 0">
    <mat-select [(value)]="enableWhenBehaviour" (selectionChange)="setEnableWhenBehaviour()">
      <mat-option *ngFor="let op of ['all', 'any']" [value]="op ? op : 'AND'">
        {{
          op === "all" ? ("page.questionnaireEditor.properties.and" | translate) : ("page.questionnaireEditor.properties.or" | translate)
        }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="spacer"></div>

  <!-- Activity fields select -->
  <div *ngIf="enableWhen.code.coding[0].system === 'activity_field'">
    <!-- Available activity fields -->
    <mat-form-field>
      <mat-select
        [(value)]="enableWhen.code.coding[0].code"
        (selectionChange)="setEnableWhen(); refreshActFieldListChoices(enableWhen.code.coding[0].code)"
        [placeholder]="'knowledgebase.knowledgeCriteria.activityField' | translate"
      >
        <mat-option *ngFor="let availableActivityField of availableActivityFields" [value]="availableActivityField">
          {{ availableActivityField }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Operator -->
    <mat-form-field>
      <mat-select [(value)]="enableWhen.operator" (selectionChange)="setEnableWhen()" disabled="true">
        <mat-option *ngFor="let operator of operatorOptions" [value]="operator">
          {{ operator }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Activity fields available choice list -->
    <mat-form-field>
      <mat-select
        [(value)]="enableWhen.answer"
        (selectionChange)="setEnableWhen()"
        [placeholder]="'page.questionnaireEditor.properties.value' | translate"
        [disabled]="!actFieldListChoices?.length"
      >
        <mat-option *ngFor="let actList of actFieldListChoices" [value]="actList.reference">
          {{ actList.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- {{Insurance select}} -->
  <div *ngIf="enableWhen.code.coding[0].code === 'ins'">
    <mat-form-field>
      <input
        #insInput
        matInput
        placeholder="{{ 'forms.insurance' | translate }}"
        [matAutocomplete]="auto"
        [ngModel]="selectedInsurances"
        (ngModelChange)="filterInsurances($event)"
      />
      <mat-autocomplete #auto="matAutocomplete" panelWidth="auto">
        <mat-option *ngFor="let insurance of filteredInsurances?.length ? filteredInsurances : availableInsurances" class="insurance">
          <mat-checkbox [checked]="insurance.selected" (change)="toggleInsuranceSelection(insurance)" (click)="$event.stopPropagation()">
            <div>
              {{ (insurance.organizationName | split : "/")[0] }}
              {{ (insurance.organizationName | split : "/")[1] ? " /" : "" }}
            </div>
            <div>{{ (insurance.organizationName | split : "/")[1] }}</div>
          </mat-checkbox>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <i>
      {{ selectedInsurances.length }}
      {{ "knowledgebase.knowledgeCriteria.selectedIns" | translate }}
    </i>
  </div>

  <!-- {{Vital Sign select}} -->
  <ng-container *ngIf="enableWhen.code.coding[0].code !== 'ins' && !enableWhen.code.coding[0].system">
    <mat-form-field>
      <mat-select
        [(value)]="enableWhen.code.coding[0].code"
        (selectionChange)="setEnableWhen()"
        [placeholder]="'common.refValue' | translate"
      >
        <mat-option *ngFor="let vs of vitalSignsDefinitions" [value]="vs.code">
          {{ vs.nameDashboard[currentLang] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Operator -->
    <mat-form-field>
      <mat-select [(value)]="enableWhen.operator" (selectionChange)="setEnableWhen()">
        <mat-option *ngFor="let operator of operatorOptions" [value]="operator">
          {{ operator }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Value -->
    <mat-form-field>
      <input
        matInput
        type="number"
        min="0"
        [(ngModel)]="enableWhen.answer"
        (change)="setEnableWhen()"
        [placeholder]="'page.questionnaireEditor.properties.value' | translate"
      />
    </mat-form-field>
  </ng-container>

  <button mat-icon-button (click)="deleteCondition()">
    <mat-icon>cancel</mat-icon>
  </button>
</div>
