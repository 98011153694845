import * as moment from "moment";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSourceExtended } from "src/app/helpers/datasource-extended";
import { InfoCommunication } from "src/app/models/communications.model";
import { CommunicationsService } from "src/app/providers/communications.service";

export class PatientCommunicationsListDataSource extends MatTableDataSourceExtended<InfoCommunication> {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private refreshInterval;
  public allDatas: InfoCommunication[];
  public loading$ = this.loadingSubject.asObservable();
  public hasData$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();
  private $subListForPatient: Subscription;

  sortingDataAccessor = (data: InfoCommunication, sortHeaderId: string): string | number => {
    switch (sortHeaderId) {
      case "status":
        return data.openStatusLocal;
      case "sendDate":
        return moment(data.sent).unix();
      case "receivedDate":
        return data.receivedDateStringGlobal.toLowerCase();
      case "sendTo":
        return data.sendTo;
      case "subject":
        return data.topic;
      default:
        return "";
    }
  };

  constructor(private comService: CommunicationsService) {
    super();
  }

  public get loading(): boolean {
    return this.loadingSubject.value;
  }

  public loadCommunications(patientId: string, fromDate: string, toDate: string, autoRefreshIntervalMs = 60000): void {
    this.loadingSubject.next(true);

    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }

    this.refreshInterval = setInterval(() => {
      this.loadData(patientId, fromDate, toDate);
    }, autoRefreshIntervalMs);
    this.loadData(patientId, fromDate, toDate);
  }

  private clearAutoRefresh(): void {
    clearInterval(this.refreshInterval);
  }

  public clear(): void {
    this.clearAutoRefresh();
    this.loadingSubject.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadData(patientId: string, fromDate: string, toDate: string): void {
    if (this.$subListForPatient) {
      this.$subListForPatient.unsubscribe();
    }
    this.$subListForPatient = this.comService
      .listForPatient(patientId, fromDate, toDate)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((res: InfoCommunication[]) => {
        this.data = res;
        this.loadingSubject.next(false);
        this.hasData$.next(this.data?.length ? true : false);
      });
  }
}
