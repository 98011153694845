import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ChartOptions } from "chart.js";
import moment from "moment";
import { BaseChartDirective } from "ng2-charts";
import { Tools } from "src/app/helpers/tools";
import { IChartColor } from "src/app/models/chart.interface";
import { IExportDrugIntakePdfBody } from "src/app/models/drugInfo.interface";
import { IEntitylink } from "src/app/models/entity.interface";
import { Notification, NOTIFICATION_STATUS } from "src/app/models/notifications.interface";
import { DrugsService } from "src/app/providers/drugs.service";
import { SessionService } from "src/app/providers/session.service";

class DataChart {
  public data: number[] = [];
  public label = "";
}

@Component({
  selector: "app-drug-graph",
  templateUrl: "./drug-graph.component.html",
  styleUrls: ["./drug-graph.component.scss"],
})
export class DrugGraphComponent implements OnInit {
  @ViewChild("baseChart") chart: BaseChartDirective;
  @Input() drug: IEntitylink;
  @Input() notifications?: Notification[];
  @Input() patientId?: string;

  public exportPdfInProgress: boolean;
  public drugsGraphRange: "days" | "weeks" | "months" = "days";
  public hasData = false;
  private currentLang: string;
  public chartData: DataChart[] = [];
  public chartLabels: Array<string> = [];
  public chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
          },
          stacked: true,
        },
      ],
    },
  };
  public chartColors: IChartColor[];

  constructor(private sessionService: SessionService, private translateService: TranslateService, private drugsService: DrugsService) {}

  ngOnInit(): void {
    this.currentLang = this.sessionService.userLang;
    this.generateHistogramData(this.drug);
  }

  // Method to update the drugs graph range
  public setDrugsGraphRange(range: "days" | "weeks" | "months"): void {
    this.drugsGraphRange = range;
    // Refresh the chart data
    this.generateHistogramData(this.drug);
  }

  private generateHistogramData(drug: IEntitylink): void {
    const relatedIntakes = this.notifications.filter(
      (intake) => intake.appId === drug._id && intake.status === NOTIFICATION_STATUS.ACCEPTED
    );
    const manualIntakes = relatedIntakes.filter((intake) => !intake.device?.reference && !intake.author?.display);
    const bluetoothIntakes = relatedIntakes.filter((intake) => intake.device?.reference);
    const practitionerIntakes = relatedIntakes.filter((intake) => intake.author?.display);

    const groupedManualData = this.groupIntakesByPeriod(manualIntakes, this.drugsGraphRange);
    const groupedBluetoothData = this.groupIntakesByPeriod(bluetoothIntakes, this.drugsGraphRange);
    const groupedPractitionerData = this.groupIntakesByPeriod(practitionerIntakes, this.drugsGraphRange);

    this.hasData = relatedIntakes?.length > 0;

    const allKeys = new Set([
      ...Object.keys(groupedManualData),
      ...Object.keys(groupedBluetoothData),
      ...Object.keys(groupedPractitionerData),
    ]);

    if (this.drugsGraphRange === "weeks") {
      this.chartLabels = Array.from(allKeys).sort((a, b) => {
        const formatForWeeks = "D MMM YYYY"; // Format for week ranges (e.g., "1 Jan 2023")

        const parseDate = (label: string): moment.Moment => {
          const startDateStr = label.split(" - ")[0]; // Extract the start date of the week range
          return moment(startDateStr, formatForWeeks, this.currentLang);
        };

        const dateA = parseDate(a);
        const dateB = parseDate(b);

        // Convert to timestamps for accurate comparison
        return dateA.valueOf() - dateB.valueOf();
      });
    } else if (this.drugsGraphRange === "months") {
      this.chartLabels = Array.from(allKeys).sort((a, b) => {
        const parseMonth = (label: string): moment.Moment => {
          return moment(label, "MMM YYYY", this.currentLang);
        };

        const dateA = parseMonth(a);
        const dateB = parseMonth(b);

        return dateA.valueOf() - dateB.valueOf();
      });
    } else {
      this.chartLabels = Array.from(allKeys);
    }

    // Set stepSize based on the selected period
    let stepSize = 1;

    if (this.drugsGraphRange === "weeks") {
      stepSize = 2;
    } else if (this.drugsGraphRange === "months") {
      stepSize = 5;
    }

    this.chartOptions.scales.yAxes[0].ticks.stepSize = stepSize;
    this.chartOptions = Tools.clone(this.chartOptions);

    this.chartData = [
      {
        data: this.chartLabels.map((label) => groupedManualData[label] || 0),
        label: this.translateService.instant("drugIntake.type.manual"),
      },
      {
        data: this.chartLabels.map((label) => groupedBluetoothData[label] || 0),
        label: this.translateService.instant("drugIntake.type.connectedDevice"),
      },
      {
        data: this.chartLabels.map((label) => groupedPractitionerData[label] || 0),
        label: this.translateService.instant("drugIntake.type.practitioner"),
      },
    ];
  }

  private groupIntakesByPeriod(intakes: Notification[], period: "days" | "weeks" | "months"): { [key: string]: number } {
    const grouped: { [key: string]: number } = {};
    const today = moment().endOf("day");

    if (period === "days") {
      const startDate = moment(today).subtract(7, "days");

      // Filter intakes to include only the last 7 days
      const filteredIntakes = intakes.filter((intake) => {
        const intakeDate = moment(intake.time);
        return intakeDate.isSameOrAfter(startDate) && intakeDate.isSameOrBefore(today);
      });

      // Group by day names (Mon, Tue, etc.)
      const days = Array.from({ length: 7 }, (_, i) => {
        const date = moment(today).subtract(i, "days");
        return date.locale(this.currentLang).format("ddd");
      }).reverse();

      // Initialize the grouped object with 0s for each of the last 7 days
      days.forEach((day) => (grouped[day] = 0));

      filteredIntakes.forEach((intake) => {
        const date = moment(intake.time);
        const intakeDay = date.locale(this.currentLang).format("ddd");

        if (days.includes(intakeDay)) {
          grouped[intakeDay] =
            (grouped[intakeDay] || 0) + (Number.isFinite(Number(intake.quantityTaken)) ? Number(intake.quantityTaken) : 1);
        }
      });

      return grouped;
    }

    // Handle 'weeks' period
    if (period === "weeks") {
      intakes.forEach((intake) => {
        const date = moment(intake.time);
        const weekRange = this.getWeekRangeWithYear(date);
        grouped[weekRange] = (grouped[weekRange] || 0) + (Number.isFinite(Number(intake.quantityTaken)) ? Number(intake.quantityTaken) : 1);
      });

      return grouped;
    }

    // Handle 'months' period
    if (period === "months") {
      intakes.forEach((intake) => {
        const date = moment(intake.time);
        const monthName = date.locale(this.currentLang).format("MMM YYYY");
        grouped[monthName] = (grouped[monthName] || 0) + (Number.isFinite(Number(intake.quantityTaken)) ? Number(intake.quantityTaken) : 1);
      });

      return grouped;
    }

    // Default to an empty object if no valid period is provided
    return grouped;
  }

  private getWeekRangeWithYear(date: moment.Moment): string {
    const startOfWeek = date.clone().startOf("isoWeek");
    const endOfWeek = date.clone().endOf("isoWeek");
    const startDateStr = startOfWeek.locale(this.currentLang).format("D MMM YYYY"); // e.g., "1 Jan 2023"
    const endDateStr = endOfWeek.locale(this.currentLang).format("D MMM YYYY"); // e.g., "7 Jan 2023"
    return `${startDateStr} - ${endDateStr}`;
  }

  public onExportPDF(): void {
    this.exportPdfInProgress = true;
    // Calculate the date range based on the current drugsGraphRange
    const today = moment().endOf("day");
    let fromDate: moment.Moment;
    const toDate: moment.Moment = today;

    if (this.drugsGraphRange === "days") {
      fromDate = moment(today).subtract(7, "days");
    } else if (this.drugsGraphRange === "weeks") {
      fromDate = moment(today).subtract(6, "weeks");
    } else if (this.drugsGraphRange === "months") {
      fromDate = moment(today).subtract(6, "months");
    }

    const canvas = document.getElementById("chart") as HTMLCanvasElement;
    const content = '<img style="height: 100%;" src="' + canvas.toDataURL() + '"/>';

    const drugIntakePdfBody: IExportDrugIntakePdfBody = {
      patientId: this.patientId,
      drugId: this.drug._id,
      fromDate: fromDate.format("YYYY-MM-DD"),
      toDate: toDate.format("YYYY-MM-DD"),
      graphic: content,
    };
    this.drugsService.createDrugIntakesPDF(drugIntakePdfBody).then(() => {
      this.exportPdfInProgress = false;
    });
  }
}
