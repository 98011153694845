import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription, fromEvent, merge, zip } from "rxjs";
import { debounceTime, distinctUntilChanged, first, last, skipWhile, takeUntil, tap } from "rxjs/operators";
import { AddToGroupDialogComponent } from "src/app/components/add-to-group-dialog/add-to-group-dialog.component";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { Item } from "src/app/components/item-selector/item-selector.component";
import { PatientDisableDialogComponent } from "src/app/components/patient-infos/patient-disable-dialog/patient-disable-dialog.component";
import { Base64Helper } from "src/app/helpers/Base64Helper";
import { FileLogger } from "src/app/helpers/fileLogger";
import { CustomErrorStateMatcher } from "src/app/helpers/formValidators";
import { HelpData } from "src/app/helpers/helpData";
import { MatriculeInsHelper } from "src/app/helpers/matriculeInsHelper";
import { Tools } from "src/app/helpers/tools";
import { DataType, Filter } from "src/app/models/filter.interface";
import { Group } from "src/app/models/group.model";
import { IPatientsListInfo, PatientUser } from "src/app/models/patient.interface";
import { PreferenceContext, PreferenceUser, TableParameter, WidgetPatientListParameter } from "src/app/models/preference.interface";
import { WidgetName } from "src/app/models/widget.interface";
import { PatientDataSource } from "src/app/pages/patient-list-page/patient-list/patient.datasource";
import { AuthService } from "src/app/providers/auth.service";
import { CommunicationsCrudService } from "src/app/providers/communications-crud.service";
import { GroupService } from "src/app/providers/group-api.service";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { PatientService } from "src/app/providers/patient.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { UserStatisticsService } from "src/app/providers/userStatistics.service";

@Component({
  selector: "app-patient-list",
  templateUrl: "./patient-list.component.html",
  styleUrls: ["./patient-list.component.scss"],
})
export class PatientListComponent implements AfterViewInit, OnInit, OnDestroy {
  /**
   * Material table
   */
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<PatientUser>;
  @ViewChild("searchInput") searchInput: ElementRef; // input mot clé dans le html
  @ViewChild("searchClearBtn", { read: ElementRef }) searchClearBtn: ElementRef;

  public searchINS: ElementRef;
  public insSearchSubscription: Subscription;
  @ViewChild("searchINS") set input(input: ElementRef) {
    if (input) {
      this.insSearchSubscription?.unsubscribe();
      // initially setter gets called with undefined
      this.searchINS = input;
      const searchINSEvent$ = fromEvent(this.searchINS.nativeElement, "keyup");
      this.insSearchSubscription = searchINSEvent$
        .pipe(
          takeUntil(this.onDestroy$),
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            if (!this.formGroup.get("ins").value) {
              // reset the patient list without ins filtering
              this.computePatientsList();
            }
          })
        )
        .subscribe();
    }
  }

  @Input() isSlimTable: boolean;

  private preferences: TableParameter;
  public dataSource: PatientDataSource;
  public patientsCount: number;
  public currentPageSize: number;
  public currentPage: number;
  public actionOnMultiple = false;
  public filters: Filter[] = [];
  public currentServices: string[] = [];
  private monitoringServices: string[];

  // Columns
  public availableColumnItems: Item[] = [];
  public displayedColumns: string[] = [];

  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";

  private withInactive = true;

  public groups: Group[];
  public formGroup: UntypedFormGroup;
  public groupId: string;
  public noGroup: Group = {
    caremateIdentifier: undefined,
    _id: "no-group",
    entityStatus: undefined,
    groupName: "no-group",
    modified: undefined,
    patients: [],
    public: undefined,
    healthcareservice: undefined,
    isValid: undefined,
  };

  public FRANCE: string = this.sessionService.FRANCE;
  public country: string;

  public selection = new SelectionModel<IPatientsListInfo>(true, []);
  public matcher = new CustomErrorStateMatcher();
  public isSelection = false;

  public isAllServices = true;
  /** Subject that emits when the component has been destroyed. */
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match
  private onDestroy$ = new Subject<void>();

  public forceSelected = false;

  private firstInit = true;

  constructor(
    private patientService: PatientService,
    private translateService: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService,
    private healthcareService: HealthcareserviceService,
    public helpData: HelpData,
    public groupService: GroupService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private crud: CommunicationsCrudService,
    private statService: UserStatisticsService,
    public responsiveService: ResponsiveService
  ) {
    // get data from resolvers
    this.preferences = this.route.snapshot.data.preferences;
    this.filters = this.preferences?.filters ? this.preferences.filters : [];
    this.currentPageSize = this.preferences?.itemsPerPage ? this.preferences.itemsPerPage : 25;
    this.patientsCount = this.route.snapshot.data.patientsCount.count;
    this.currentPage = this.sessionService.pageIndex;
    this.formGroup = this.fb.group({
      groupFilter: [""],
      ins: ["", MatriculeInsHelper.insValidator],
    });

    if (
      this.sessionService.currentService &&
      this.userService.ownOrganization &&
      (this.healthcareService.availableServices()?.length > 0 || this.healthcareService.availableMonitoringServices().length > 0)
    ) {
      this.setupServicesWatch();
    } else {
      this.sessionService.servicesSetupWatch
        .pipe(
          skipWhile(() => !this.userService.ownOrganization),
          first()
        )
        .subscribe(() => {
          this.setupServicesWatch();
        });
    }
  }

  ngOnInit(): void {
    // Create DataSource and first patients loading
    this.dataSource = new PatientDataSource(this.patientService);
    // apply filter
    this.filters?.forEach((filter) => this.dataSource.setFilter(filter));
  }

  ngAfterViewInit(): void {
    // Loading Patient logic
    this.computePatientsList();
    this.sessionService.refreshPatientDataList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.computePatientsList();
    });

    // server-side search
    const searchEvent$ = fromEvent(this.searchInput.nativeElement, "keyup");
    const searchResetEvent$ = fromEvent(this.searchClearBtn.nativeElement, "click");

    merge(searchEvent$, searchResetEvent$)
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.computePatientsList();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadPatients();
          this.resetSelection();
        })
      )
      .subscribe();

    // Detect group change and refresh patients list

    this.formGroup
      .get("groupFilter")
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.groupId = v?._id;
        this.resetSelection();
        this.updatePreference();
        this.computePatientsList();
      });

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page: PageEvent) => {
      this.sessionService.pageIndex = page.pageIndex;
      this.currentPageSize = this.paginator.pageSize;
      this.updatePreference();
    });
  }

  ngOnDestroy(): void {
    this.updatePatientWidgetPreferences().pipe(last()).subscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private init(firstInit = false) {
    this.firstInit = false;
    if (this.userService.isMonitoringUser) {
      if (!this.sessionService.currentMonitoringService) {
        this.monitoringServices = [];
      } else if (this.sessionService.currentMonitoringService.reference === this.sessionService.allsOption) {
        this.monitoringServices = this.healthcareService.availableMonitoringServices()?.map((s) => s.asReference.reference);
      } else {
        this.monitoringServices = [this.sessionService.currentMonitoringService?.reference];
      }
    }

    if (
      !this.sessionService.currentService ||
      !this.healthcareService.availableServices() ||
      this.healthcareService.availableServices().length < 1
    ) {
      this.patientsCount = 0;
      this.paginator.pageIndex = 0;
      this.currentServices = [];
      this.reloadGroups();
      this.loadPatients();
      return;
    }
    if (!this.sessionService.currentService) {
      this.currentServices = [];
    } else if (this.sessionService.currentService.reference === this.sessionService.allsOption) {
      this.currentServices = this.healthcareService.availableServices().map((s) => s.asReference.reference);
    } else {
      this.currentServices = [this.sessionService.currentService?.reference];
    }

    // if service has changed, reset the selected group which will trigger patients refresh; else : refresh patients
    if (firstInit) {
      this.reloadGroups(this.preferences?.group);
    } else {
      this.reloadGroups();
    }
  }

  private setupServicesWatch() {
    this.setupServices();
    this.setupColumns();
    this.init(this.firstInit);
    if (this.userService.isMonitoringUser) {
      this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
        this.setupColumns();
        this.init(this.firstInit);
      });
    }
    this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.setupServices();
      this.setupColumns();
      // trigger @Input setter on filtersDisplay comp. to allow filter name translation for french services (CMATE-5279)
      this.filters = [...this.filters];
      this.init(this.firstInit);
    });
  }

  private setupServices() {
    if (
      (!this.userService.isMonitoringUser && !this.sessionService.currentService) ||
      (this.userService.isMonitoringUser && !this.sessionService.currentMonitoringService)
    ) {
      this.country = this.sessionService.currentCountry;
      this.isAllServices = true;
      return;
    }
    this.isAllServices = this.sessionService.isAllServices(this.userService.isMonitoringUser);
    this.country = this.sessionService.currentCountry;
  }

  /**
   * Columns must be set up everytime the service is updated because the translation of the columns names depends on the service country
   */
  private setupColumns() {
    this.initColumn();
    // remove unexisting column saved in preferences
    if (this.preferences?.availableColumnItems) {
      this.preferences.availableColumnItems.forEach((columun, index) => {
        const exist = this.availableColumnItems.find((c) => c.value === columun.value);
        if (!exist) {
          delete this.preferences.availableColumnItems[index];
        }
      });
      // add unexisting column saved in preferences
      this.availableColumnItems.find((column) => {
        const exist = this.preferences.availableColumnItems.find((c) => c?.value === column?.value);
        if (!exist) {
          this.preferences.availableColumnItems.push(column);
        }
      });
      // Restore column order + visibility
      this.preferences.availableColumnItems = this.preferences.availableColumnItems.filter((v) => v);

      // get correct display in case the service country is not the same
      this.availableColumnItems = this.preferences.availableColumnItems.map((item: Item) => {
        const matchingItem = this.availableColumnItems.find((i) => i.value === item.value);
        if (matchingItem) {
          item.display = matchingItem.display;
        }
        return item;
      });
    }
    this.setDisplayedColumns();
  }

  private computePatientsList(insNumber?: string) {
    if (!this.currentServices || this.currentServices.length < 1) {
      this.patientsCount = 0;
      this.paginator.pageIndex = 0;
      this.loadPatients();
      return;
    }
    this.patientService
      .getPatientsCount(
        this.currentServices,
        this.searchInput.nativeElement.value,
        this.getFilters(),
        this.withInactive,
        this.groupId,
        this.sessionService.globalPref?.inactifPatientDuration,
        this.monitoringServices,
        insNumber
      )
      .pipe(first())
      .subscribe((result) => {
        this.patientsCount = result?.count;
        this.paginator.pageIndex = 0;
        this.loadPatients(insNumber);
      });
  }

  private loadPatients(insNumber?: string) {
    this.dataSource.loadData({
      sortId: this.sort.active,
      sortOrder: this.sort.direction,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      filters: !insNumber ? this.getFilters() : [], // no need for other filters if we search by insNumber
      search: !insNumber ? this.searchInput.nativeElement.value : "",
      withInactive: this.withInactive,
      groupId: this.groupId,
      services: this.currentServices,
      monitoringServicesIds: this.monitoringServices,
      lang: this.sessionService.userLang,
      period: this.sessionService.globalPref?.inactifPatientDuration,
      insNumber,
    });
  }

  private initColumn(): void {
    if (this.isSlimTable) {
      // Define all available columns
      this.availableColumnItems = [
        {
          value: "status",
          display: "model.patient.status",
          checked: true,
        },
        {
          value: "name",
          display: this.country === this.FRANCE ? "forms.FrName" : "model.patient.name",
          checked: true,
        },
        {
          value: "firstname",
          display: this.country === this.FRANCE ? "forms.FrFirstname" : "model.patient.firstname",
          checked: true,
        },
        {
          value: "pseudoname",
          display: "model.patient.pseudoname",
          checked: true,
        },
        {
          value: "birthday",
          display: "model.patient.birthdate",
          checked: true,
        },
        {
          value: "gender",
          display: "model.patient.gender",
          checked: true,
        },
        {
          value: "lastActivity",
          display: "model.patient.lastActivity",
          checked: true,
        },
      ];
    } else {
      // Define all available columns
      this.availableColumnItems = [
        {
          value: "status",
          display: "model.patient.status",
          checked: true,
        },
        {
          value: "name",
          display: this.country === this.FRANCE ? "forms.FrName" : "model.patient.name",
          checked: true,
        },
        {
          value: "useName",
          display: "forms.useName",
          checked: false,
        },
        {
          value: "firstname",
          display: this.country === this.FRANCE ? "forms.FrFirstname" : "model.patient.firstname",
          checked: true,
        },
        {
          value: "useFirstname",
          display: "forms.useFirstname",
          checked: false,
        },
        {
          value: "pseudoname",
          display: "model.patient.pseudoname",
          checked: false,
        },
        {
          value: "birthday",
          display: "model.patient.birthdate",
          checked: true,
        },
        {
          value: "phone",
          display: "model.patient.phone",
          checked: true,
        },
        {
          value: "email",
          display: "model.patient.email",
          checked: false,
        },
        {
          value: "gender",
          display: "model.patient.gender",
          checked: true,
        },
        {
          value: "creation",
          display: "model.patient.creation",
          checked: true,
        },
        {
          value: "healthcare",
          display: "model.patient.healthcare",
          checked: false,
        },
        {
          value: "managingOrganization",
          display: "model.patient.organization",
          checked: false,
        },
        {
          value: "lastActivity",
          display: "model.patient.lastActivity",
          checked: true,
        },
        // { value: 'nationalNumber', display: trans['model.patient.nationalNumber'], checked: false },
        // see CMATE-1313
        {
          value: "careplan",
          display: "model.patient.careplan",
          checked: false,
        },
        {
          value: "careProvider",
          display: "model.patient.careProvider",
          checked: true,
        },
        {
          value: "insurance",
          display: "model.patient.insurance",
          checked: false,
        },
        // { value: 'nationalNumber', display: trans['model.patient.nationalNumber'], checked: false }
        // see CMATE-1313
      ];
    }
  }

  public setDisplayedColumns(): void {
    this.displayedColumns = this.actionOnMultiple
      ? ["select", ...this.availableColumnItems.filter((item) => item.checked).map((item) => item.value)]
      : [...this.availableColumnItems.filter((item) => item.checked).map((item) => item.value)];
  }

  public availableColumnChanged(_items: Item[]): void {
    this.setDisplayedColumns();
    this.updatePreference();
  }

  /**
   * Row interactions
   */
  public patientRowClicked(patient: IPatientsListInfo): void {
    if (this.actionOnMultiple) {
      this.selection.toggle(patient);
    } else if (this.userService.isAuthorizedSync(null, "/patient", "GET")) {
      this.sessionService.patientPageFrom = "/patients";
      const b64Id = Base64Helper.utf8_to_b64(patient.caremateIdentifier);
      this.router.navigateByUrl(`/patient;id=${b64Id}`, {
        state: {
          patient,
          widget: WidgetName.PATIENT_LIST,
        },
      });
    }
  }

  /**
   * Drag n'Drop
   */
  public columnDropped(event: CdkDragDrop<string[]>): void {
    const realIndexFrom =
      this.availableColumnItems.findIndex((item) => this.displayedColumns[event.previousIndex] === item.value) +
      (this.actionOnMultiple ? 1 : 0);
    const realIndexTo =
      this.availableColumnItems.findIndex((item) => this.displayedColumns[event.currentIndex] === item.value) +
      (this.actionOnMultiple ? 1 : 0);
    moveItemInArray(this.availableColumnItems, realIndexFrom, realIndexTo);
    this.setDisplayedColumns();
    this.updatePreference();
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter, isInit = false): void {
    this.selection.clear();
    this.forceSelected = false;
    this.dataSource.setFilter(filter);
    this.statService.createStatEvent("Used filter on patients list (" + filter.propertyName + ")");
    this.sessionService.needRefreshPatientDataList();
    // only reinit pageIndex if not init filiter
    if (!isInit) {
      this.sessionService.pageIndex = this.paginator.pageIndex;
    }
    this.updatePreference();
  }

  public clearFilter(): void {
    this.sort.active = "name";
    this.sort.direction = "asc";
    this.searchInput.nativeElement.value = "";
    this.dataSource.clearFilter();
    this.paginator.pageIndex = 0;
    this.isSelection = false;
    this.filters = [];
    this.sessionService.needRefreshPatientDataList();
    this.updatePreference();
  }

  private getFilters(): Filter[] {
    return (this.filters = [...this.dataSource.getAllFilters()]);
  }
  /**
   * Selection
   */
  public isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public masterToggle(): void {
    this.dataSource
      .connect(this.table)
      .pipe(first())
      .subscribe((rows) => {
        if (this.selection.hasValue() || this.forceSelected) {
          this.selection.clear();
          this.forceSelected = false;
        } else {
          this.selection.select(...rows);
        }
      });
  }

  // Actions
  public disableSelected(): void {
    let patientUsers: PatientUser[] = [];

    this.getPatientUsers(this.selection.selected).then(
      (pu: PatientUser[]) => {
        patientUsers = pu;
        this.dialog
          .open(PatientDisableDialogComponent, {
            data: { patients: patientUsers },
            disableClose: true,
          })
          .afterClosed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => {
            this.resetSelection();
            this.sessionService.needRefreshPatientDataList();
          });
      },
      (error) => {
        FileLogger.error("PatientListPageComponent", "disableSelected", error);
      }
    );
  }

  public activateSelected(): void {
    this.translateService
      .get(
        this.selection.selected.length > 1 ? "page.patientlist.activatePatientPluralConfirm" : "page.patientlist.activatePatientConfirm",
        { length: this.selection.selected.length }
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((trans: string) => {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: { message: trans, type: ConfirmationDialogType.CONFIRM },
        });

        dialogRef.afterClosed().subscribe(async (yes) => {
          if (!yes) {
            return;
          }
          this.getPatientUsers(this.selection.selected).then(
            async (pu: PatientUser[]) => {
              pu.forEach(async (patientUser) => {
                await this.patientService.activate(patientUser.patient).pipe(first()).toPromise();
              });
            },
            (error) => {
              FileLogger.error("PatientListPageComponent", "activateSelected", error);
            }
          );

          this.resetSelection();
          this.sessionService.needRefreshPatientDataList();
        });
      });
  }

  public resetPasswordSelected(): void {
    const disableObs = this.selection.selected.map((patient) => {
      return this.authService.resetPassword(patient.caremateIdentifier);
    });
    zip(...disableObs)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.selection.clear();
        this.translateService
          .get("common.success")
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((trans) => {
            this.snackBar.open(trans, "ok", { duration: 3000 });
          });
      });
  }

  /**
   * Preferences
   */
  public updatePreference(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          availableColumnItems: this.availableColumnItems,
          itemsPerPage: this.paginator.pageSize,
          group: this.formGroup.get("groupFilter").value,
        } as TableParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: PreferenceUser) => {
        const param = parameters.preferences.find((p) => p.context === PreferenceContext.PATIENT_LIST);
        this.preferences = param.parameters;
      });
  }

  private updatePatientWidgetPreferences() {
    // update also the patient list widget for get the sorted list of the page
    return this.preferenceService.update({
      context: PreferenceContext.WIDGET_PATIENT_LIST,
      parameters: {
        filters: this.filters,
        patientsCount: this.patientsCount,
        search: this.searchInput.nativeElement.value,
        groupId: this.groupId,
      } as WidgetPatientListParameter,
    });
  }

  public activateCheckBox(): void {
    this.actionOnMultiple = !this.actionOnMultiple;
    if (this.actionOnMultiple) {
      // this.dataSource.clearAutoRefresh();
      this.displayedColumns.unshift("select");
    } else {
      this.resetSelection();
    }
  }

  public getCareProvidersDisplay(patient: IPatientsListInfo): string[] {
    return patient.careProvider.map((c) => c?.display);
  }

  public openPatientsHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.patientListPageHelp },
      disableClose: true,
    });
  }

  public async addToGroup(): Promise<void> {
    let list: PatientUser[] = [];
    this.getPatientUsers(this.selection.selected).then(
      (pu: PatientUser[]) => {
        list = pu;
        this.dialog
          .open(AddToGroupDialogComponent, {
            data: { list },
            disableClose: true,
          })
          .afterClosed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((res) => {
            if (res) {
              this.reloadGroups();
              this.sessionService.needRefreshPatientDataList();
              this.resetSelection();
            }
          });
      },
      (error) => {
        FileLogger.error("PatientListPageComponent", "addToGroup", error);
      }
    );
  }

  public async removeFromGroup(): Promise<void> {
    let list: PatientUser[] = [];
    this.getPatientUsers(this.selection.selected).then(
      (pu: PatientUser[]) => {
        list = pu;
        const selectedGroup = this.formGroup.get("groupFilter").value as Group;
        this.dialog
          .open(AddToGroupDialogComponent, {
            data: { list, isDelete: true },
            disableClose: true,
          })
          .afterClosed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((res) => {
            if (res) {
              this.reloadGroups(selectedGroup);
              this.sessionService.needRefreshPatientDataList();
              this.resetSelection();
            }
          });
      },
      (error) => {
        FileLogger.error("PatientListPageComponent", "removeFromGroup", error);
      }
    );
  }

  private reloadGroups(group?: Group): void {
    if (!this.userService.isAuthorizedSync(null, "groups", "GET")) {
      this.groups = [];
      this.selection.clear();
      this.formGroup.get("groupFilter").setValue(null);
      return;
    }
    let services: string[] = null;
    let current: string = null;
    if (this.userService.isMonitoringUser) {
      services = this.monitoringServices;
      current = this.sessionService.currentMonitoringService?.reference;
    } else {
      services = this.currentServices;
      current = this.sessionService.currentService?.reference;
    }
    if (!services || !current) {
      this.groups = [];
      this.selection.clear();
      this.formGroup.get("groupFilter").setValue(null);
      return;
    }
    this.groupService
      .list(current, services)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((groups) => {
        this.groups = Tools.clone(groups);
        groups.unshift(this.noGroup); // add the no-group option in order too display the select correctly
        group = groups.find((g) => g._id === group?._id);
        this.selection.clear();
        if (group) {
          this.formGroup.get("groupFilter").setValue(group);
        } else {
          this.formGroup.get("groupFilter").setValue(null);
        }
      });
  }

  public async sendCommunication(): Promise<void> {
    let patientUsers: PatientUser[] = [];
    this.getPatientUsers(this.selection.selected).then(
      (pu: PatientUser[]) => {
        patientUsers = pu;
        const selection = this.patientService.toReference(patientUsers);
        const group = this.formGroup.get("groupFilter")?.value;
        this.crud.createCommunication(selection, undefined, group); // allPatients undefined to let the modal loading all Patients.
      },
      (error) => {
        FileLogger.error("PatientListPageComponent", "sendCommunication", error);
      }
    );
  }

  public getPatientUsers(patients: IPatientsListInfo[]): Promise<PatientUser[]> {
    const caremateIdentifiers = patients.map((patient) => patient.caremateIdentifier);
    return this.patientService.getPatientUsers(caremateIdentifiers).pipe(first()).toPromise();
  }

  public selectAllPatients(): void {
    this.patientService
      .getPatientListInfo({
        sortId: this.sort.active,
        sortOrder: this.sort.direction,
        // pageNumber: this.paginator.pageIndex,
        pageSize: this.patientsCount, // set patientsCount as a pageSize to get all patients now that pageSize is mandatory in backend.
        filters: this.filters,
        search: this.searchInput.nativeElement.value,
        withInactive: this.withInactive,
        groupId: this.groupId,
        services: this.currentServices,
        lang: this.sessionService.userLang,
        period: this.sessionService.globalPref?.inactifPatientDuration,
      })
      .pipe(first())
      .subscribe((list) => {
        this.selection.clear();
        this.selection.select(...list);
        this.forceSelected = true;
      });
  }

  public resetSelection(): void {
    this.selection.clear();
    this.actionOnMultiple = false;
    this.forceSelected = false;
    // remove 'select' column from displayedColumns
    if (this.displayedColumns[0] === "select") {
      this.displayedColumns.shift();
    }
  }

  public compareGroupId(o1: Group, o2: Group): boolean {
    return o1?._id === o2?._id;
  }

  /**
   * Search for a patient using its ins number
   * @param insNumber the ins id of the patient
   * @returns
   */
  public searchViaIdentifierNumber(insNumber?: string): void {
    if (!insNumber) {
      this.computePatientsList();
    } else if (!this.formGroup.get("ins").errors) {
      this.computePatientsList(insNumber);
    }
  }
}
