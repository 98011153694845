import { Component, Input } from "@angular/core";
import { IObservationDefinition, OComponent } from "src/app/models/observations.interface";
import { Observation } from "src/app/models/observations.model";

import { MatDialog } from "@angular/material/dialog";
import { RULE_LEVEL } from "src/app/models/rule.interface";
import { ObservationsService } from "src/app/providers/observations.service";
import { SessionService } from "src/app/providers/session.service";
import { GetComponentPipe } from "../patient-observations-pipes/get-component.pipe";
import { TranslateComponentPipe } from "../patient-observations-pipes/translate-component.pipe";

@Component({
  selector: "app-default-observation-table",
  templateUrl: "./default-observation-table.component.html",
  styleUrls: ["./default-observation-table.component.scss"],
})
export class DefaultObservationTableComponent {
  @Input() obsDefinition: IObservationDefinition[];
  @Input() observations: Observation[];
  @Input() aggregateByDay: boolean;
  @Input() headers: OComponent[];
  @Input() initialObs: Observation[];

  public RULE_LEVEL = RULE_LEVEL;
  private getComponentPipe = new GetComponentPipe();
  private translateComponentPipe = new TranslateComponentPipe(this.sessionService);
  constructor(public observationService: ObservationsService, public sessionService: SessionService, private dialog: MatDialog) {}

  public showPictures(observation: Observation, headerCompo: OComponent): void {
    const component = this.getComponentPipe.transform(headerCompo, observation.component);
    const name = this.translateComponentPipe.transform(component, this.obsDefinition);
    this.observationService.showPictures(component, name, this.observations);
  }

  public openAlertHistory(headerCompo: OComponent, components: OComponent[]): void {
    const clickedComponent = this.getComponentPipe.transform(headerCompo, components);
    this.observationService.openAlertHistory(clickedComponent.parentObservation, this.obsDefinition);
  }

  public showGraph(observation: Observation, headerCompo: OComponent): void {
    const component = this.getComponentPipe.transform(headerCompo, observation.component);
    this.observationService.showGraph(component.valueQuantity.valueArray);
  }
}
