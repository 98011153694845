<ng-container *ngIf="!isMatMenu; else menu">
  <button
    mat-icon-button
    matTooltip="{{ 'btn.addPatient' | translate }}"
    class="color-button"
    *ngIf="isServiceSelected && ('patient' | isAuthorized : 'POST' | async) === true"
    (click)="addPatient()"
    [disabled]="isMonitoringUser && isAllServices"
    [matTooltipDisabled]="isMonitoringUser && isAllServices"
    aria-label="icon button with a user icon"
  >
    <mat-icon matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isMonitoringUser || !isAllServices"
      >person_add</mat-icon
    >
  </button>
  <button
    mat-icon-button
    matTooltip="{{ 'communication.newComm' | translate }}"
    class="color-button"
    (click)="newCommunication()"
    aria-label="icon button with a enveloppe icon"
    *ngIf="isServiceSelected && ('dashboard/communication' | isAuthorized : 'POST' | async) === true"
    [disabled]="isAllServices"
    [matTooltipDisabled]="isAllServices"
  >
    <mat-icon matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isAllServices"> mail </mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="{{ 'page.drugSchema.title' | translate }}"
    class="color-button"
    *ngIf="('/drugSchema' | isAuthorized | async) === true"
    routerLink="/drugSchema"
    routerLinkActive="active"
    aria-label="icon button with a timelapse icon"
  >
    <mat-icon>timelapse</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="{{ 'group.group' | translate }}"
    class="color-button"
    *ngIf="('/groups' | isAuthorized | async) === true"
    routerLink="/groups"
    routerLinkActive="active"
    aria-label="icon button with a group icon"
  >
    <mat-icon>supervisor_account</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="{{ 'btn.addVideoCall' | translate }}"
    class="color-button"
    *ngIf="areBothServicesSelected && ('appointment' | isAuthorized : 'POST' | async) === true"
    (click)="addVideoCall()"
    aria-label="icon button with a user icon"
    [disabled]="isEitherServicesAllMode"
    [matTooltipDisabled]="isEitherServicesAllMode"
  >
    <mat-icon matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isEitherServicesAllMode"> video_call </mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="{{ 'btn.preferences' | translate }}"
    class="color-button"
    (click)="openPreferenceDialog()"
    aria-label="icon button with a user icon"
  >
    <mat-icon>account_circle</mat-icon>
  </button>
  <button mat-icon-button matTooltip="{{ 'btn.help' | translate }}" aria-label="icon button with a ? icon" (click)="openHelpDialog()">
    <mat-icon class="color-button">help_outline</mat-icon>
  </button>
</ng-container>

<ng-template #menu>
  <button
    mat-menu-item
    *ngIf="isServiceSelected && ('patient' | isAuthorized : 'POST' | async) === true"
    (click)="addPatient()"
    [disabled]="isMonitoringUser && isAllServices"
    aria-label="icon button with a user icon"
  >
    <mat-icon matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isMonitoringUser || !isAllServices"
      >person_add</mat-icon
    >
    <span>{{ "btn.addPatient" | translate }}</span>
  </button>
  <button
    mat-menu-item
    (click)="newCommunication()"
    aria-label="icon button with a enveloppe icon"
    *ngIf="isServiceSelected && ('dashboard/communication' | isAuthorized : 'POST' | async) === true"
    [disabled]="isAllServices"
  >
    <mat-icon matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isAllServices"> mail </mat-icon>
    <span>{{ "communication.newComm" | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="('/drugSchema' | isAuthorized | async) === true"
    routerLink="/drugSchema"
    routerLinkActive="active"
    aria-label="icon button with a timelapse icon"
  >
    <mat-icon>timelapse</mat-icon>
    <span>{{ "page.drugSchema.title" | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="('/groups' | isAuthorized | async) === true"
    routerLink="/groups"
    routerLinkActive="active"
    aria-label="icon button with a group icon"
  >
    <mat-icon>supervisor_account</mat-icon>
    <span>{{ "group.group" | translate }}</span>
  </button>
  <button
    mat-menu-item
    *ngIf="areBothServicesSelected && ('appointment' | isAuthorized : 'POST' | async) === true"
    (click)="addVideoCall()"
    aria-label="icon button with a user icon"
    [disabled]="isEitherServicesAllMode"
  >
    <mat-icon matTooltip="{{ 'btn.disabledWithAll' | translate }}" [matTooltipDisabled]="!isEitherServicesAllMode"> video_call </mat-icon>
    <span>{{ "btn.addVideoCall" | translate }}</span>
  </button>
  <button mat-menu-item (click)="openPreferenceDialog()" aria-label="icon button with a user icon">
    <mat-icon>account_circle</mat-icon>
    <span>{{ "btn.preferences" | translate }}</span>
  </button>
  <button mat-menu-item aria-label="icon button with a ? icon" (click)="openHelpDialog()">
    <mat-icon>help_outline</mat-icon>
    <span>{{ "btn.help" | translate }}</span>
  </button>
  <button mat-menu-item class="fail" (click)="onLogout()" aria-label="icon button with a logout icon">
    <mat-icon class="fail">exit_to_app</mat-icon>
    <span>{{ "menu.logout" | translate }}</span>
  </button>
</ng-template>
