<div class="buttons-bar">
  <button mat-icon-button color="warn" class="negative-margin" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <canvas
    baseChart
    #baseChart="base-chart"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [colors]="chartColors"
    legend="true"
    [chartType]="'line'"
  ></canvas>
</div>
