import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EntityDrug } from "../models/entity.interface";
import { IKnowMedia, IKnowledgeBase, IKnowledges, KNOW_DOC_CATEGORY, KNOW_DOC_TYPE, MEDIA_CATEGORY } from "../models/knowledge.interface";
import { Reference } from "../models/reference.interface";
import { KnowledgeApiService } from "./api/knowledge-api.service";

@Injectable({
  providedIn: "root",
})
export class KnowledgeService {
  constructor(private api: KnowledgeApiService, private fb: UntypedFormBuilder) {}

  public publishKnowledge(knowledgeId: string): Observable<IKnowledgeBase> {
    return this.api.publish(knowledgeId);
  }

  public getKnowledge(identifier: string): Observable<{
    published: IKnowledgeBase;
    draft: IKnowledgeBase;
  }> {
    return this.api.getKnowledgeDetails(identifier) as Observable<{
      published: IKnowledgeBase;
      draft: IKnowledgeBase;
    }>;
  }

  public createDraft(knowledge: IKnowledgeBase): Observable<IKnowledgeBase> {
    knowledge.publicationDate = null;
    delete knowledge._id;
    return this.api.create(knowledge);
  }

  public updateDraft(knowledge: IKnowledgeBase): Observable<IKnowledgeBase> {
    knowledge.publicationDate = null;
    return this.api.update(knowledge);
  }

  public updateServices(knowledgeId: string, healthcareservices: Reference[]): Observable<boolean> {
    return this.api.updateServices(knowledgeId, healthcareservices) as Observable<boolean>;
  }

  public deleteDraft(id: string): Observable<unknown> {
    // mongo id
    return this.api.deleteDraft(id);
  }

  public getCareplansByKnowledge(
    knowledgeId: string,
    lang: string
  ): Observable<
    {
      reference: string;
      name: string;
    }[]
  > {
    return this.api.getCareplansByKnowledge(knowledgeId, lang);
  }

  /**
   * Recompose the media.content by joining the slides together with tag <hr id="null"> and integrating the slide title as <h1> tag
   */
  public aggregateSlides(form: UntypedFormGroup): string {
    let newContent = "";
    form.get("content").value.forEach((slide, index) => {
      const h1Tag = slide.showInSummary ? `<h1 id='${index}'>${slide.title.trim()}</h1>` : "";
      newContent = newContent + h1Tag + slide.text + (index < form.get("content").value.length - 1 ? '<hr id="null">' : "");
    });
    return newContent;
  }

  /**
   * Seperate media.content in slides based on the <hr id="null"> tags within the content
   * and extract first <h1> tag to be used as the slide Title
   */
  public slideItUp(form: UntypedFormGroup, media: IKnowMedia): UntypedFormGroup {
    if (!media) {
      // create empty slide
      (form.get("content") as UntypedFormArray).push(
        this.fb.group({
          title: ["", Validators.required],
          text: ["", Validators.required],
          showInSummary: [true],
        })
      );
      return form;
    }

    const slides = media.content.split('<hr id="null">');
    slides.forEach((slide, i) => {
      const el = document.createElement("div");
      el.innerHTML = slide;
      // get slide title (first h1 within the slide content)
      const slideTitle = el.getElementsByTagName("h1")[0];
      let showInSummary: boolean;
      if (slideTitle?.id === i.toString()) {
        showInSummary = true;
      } else {
        showInSummary = false;
      }
      // remove the title from the slide content
      slideTitle?.remove();

      (form.get("content") as UntypedFormArray).push(
        this.fb.group({
          title: [slideTitle?.innerText, Validators.required],
          text: [el.innerHTML, Validators.required],
          showInSummary: [showInSummary],
        })
      );
    });
    return form;
  }

  public patchedMediaValuesButContent(media: IKnowMedia): {
    category: MEDIA_CATEGORY;
    description: string;
    importanceLevel: number;
    label: string;
    language: string;
    type: KNOW_DOC_TYPE;
    specificQuestionnaire: string[];
    visibleForPatient: boolean;
    visibleForMonitoring: boolean;
  } {
    return {
      category: media.category,
      description: media.description ? media.description : "",
      importanceLevel: media.importanceLevel ? media.importanceLevel : null,
      label: media.label,
      language: media.language,
      type: media.type,
      specificQuestionnaire: media.specificQuestionnaire,
      visibleForPatient: !media.notVisibleForPatient,
      visibleForMonitoring: media.visibleForMonitoring,
    };
  }

  public getMediasCareplan(patientId: string, docCategory: KNOW_DOC_CATEGORY, careplanId: string, lang?: string): Observable<IKnowMedia[]> {
    return this.api.getKnowledgesCareplans(patientId, docCategory, careplanId, lang).pipe(
      map((result) => {
        const medias: IKnowMedia[] = [];
        if (result.length === 1) {
          result[0].knowledges.forEach((k) => {
            medias.push(...k.medias);
          });
        }
        return medias;
      })
    );
  }

  public getDrugsKnowledges(drugs: EntityDrug[]): Observable<IKnowledges[]> {
    const refs = drugs.map((d) => d?.name);
    return this.api.getDrugsKnowledges(refs);
  }

  public getMediaFromIdentifier(mediaId: string): Observable<IKnowMedia> {
    return this.api.getMediaFromIdentifier(mediaId);
  }
}
