<ng-container *ngIf="hasVisibleActions$ | async">
  <div *ngIf="!isMobile; else mobileActions" class="desktop-actions">
    <ng-container *ngFor="let action of actions">
      <ng-container [ngSwitch]="action.type">
        <ng-container *ngSwitchCase="'button'">
          <button
            mat-icon-button
            *ngIf="!action.condition$ || (action.condition$ | async)"
            [disabled]="action.conditionDisable$ && (action.conditionDisable$ | async) === true"
            matTooltip=" {{
              (action.disabledTooltip && (action.conditionDisable$ | async) === true ? action.disabledTooltip : action.label) | translate
            }}"
            (click)="onAction(action.action)"
            [attr.aria-label]="action.ariaLabel"
          >
            <mat-icon>{{ action.icon }}</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'toggle'">
          <mat-slide-toggle
            labelPosition="before"
            (click)="$event.stopPropagation()"
            (change)="onAction(action.action, $event)"
            [checked]="action.toggleState$ | async"
          >
            <span style="font-size: 12px" *ngIf="action.label$ | async">{{ action.label$ | async }}</span>
            <span style="font-size: 12px">{{ action.label | translate }}</span>
          </mat-slide-toggle>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #mobileActions>
  <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="{{ 'btn.actions' | translate }}" aria-label="icon button with menu icon">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let action of actions">
      <ng-container [ngSwitch]="action.type">
        <ng-container *ngSwitchCase="'button'">
          <!-- Disabled buttons are hidden on mobile -->
          <button
            mat-menu-item
            [attr.aria-label]="action.ariaLabel"
            *ngIf="!action.condition$ || (action.condition$ | async)"
            (click)="onAction(action.action)"
            [disabled]="action.conditionDisable$ && (action.conditionDisable$ | async) === true"
            matTooltip=" {{ action.disabledTooltip | translate }}"
            [matTooltipDisabled]="!action.conditionDisable$ || (action.conditionDisable$ | async) === false"
          >
            <mat-icon>{{ action.icon }}</mat-icon>
            <span style="font-size: 12px" *ngIf="action.label$ | async">{{ action.label$ | async }}</span>
            <span style="font-size: 12px">{{ action.label | translate }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'toggle'">
          <mat-slide-toggle
            style="margin: 0 1rem"
            labelPosition="left"
            (click)="$event.stopPropagation()"
            (change)="onAction(action.action, $event)"
            [checked]="action.toggleState$ | async"
          >
            <span style="font-size: 12px" *ngIf="action.label$ | async">{{ action.label$ | async }}</span>
            <span style="font-size: 12px">{{ action.label | translate }}</span>
          </mat-slide-toggle>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-menu>
</ng-template>
