<div class="preferenceDialog">
  <mat-dialog-content class="content">
    <!-- MONITORING ORGANIZATION SELECT -->
    <mat-form-field id="selectMonitoringOrganization" *ngIf="isMonitoringUser && availableMonitOrgs?.length > 1 && currentMonitOrg">
      <mat-select
        [placeholder]="'common.monitoringOrg' | translate"
        [compareWith]="compareReference"
        (selectionChange)="changeMonitoringOrganization($event)"
        [value]="currentMonitOrg"
        [aria-label]="'Select monitoring organization'"
      >
        <mat-option *ngIf="availableMonitOrgs.length >= 2" [value]="sessionService.allsReference">
          <span id="monitOrgAll">{{ "page.preference.all" | translate }}</span>
        </mat-option>
        <mat-option *ngFor="let orga of availableMonitOrgs; let i = index" [value]="orga">
          <span attr.id="monitOrg{{ i }}">{{ orga.display }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- MONITORING SERVICE SELECT -->
    <mat-form-field id="selectMonitoringService" *ngIf="isMonitoringUser && availableMonitServices?.length > 1 && currentMonitService">
      <mat-select
        [placeholder]="'common.monitoringService' | translate"
        [compareWith]="compareReference"
        (selectionChange)="changeMonitoringService($event)"
        [value]="currentMonitService"
        [aria-label]="'Select monitoring service'"
      >
        <mat-option *ngIf="availableMonitServices.length >= 2" [value]="sessionService.allsReference">
          <span id="monitServAll">{{ "page.preference.all" | translate }}</span>
        </mat-option>
        <mat-option *ngFor="let service of availableMonitServices; let i = index" [value]="service">
          <span attr.id="monitServ{{ i }}">{{ service.display }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- ORGANIZATION SELECT -->
    <mat-form-field>
      <mat-select
        [placeholder]="'page.organizations.searchPlaceHolder' | translate"
        [value]="currentOrganization"
        [aria-label]="'Select organization'"
        [compareWith]="compareReference"
        (selectionChange)="changeOrganization($event)"
      >
        <mat-option *ngIf="availableOrganizations.length >= 2" [value]="sessionService.allsReference">
          <span id="orgAll">{{ "page.preference.all" | translate }}</span>
        </mat-option>
        <mat-option *ngFor="let orga of availableOrganizations; let i = index" [value]="orga">
          <span attr.id="org{{ i }}">{{ orga.display }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- SERVICE SELECT -->
    <mat-form-field id="selectService">
      <mat-select
        [placeholder]="'common.service' | translate"
        [value]="currentService"
        [aria-label]="'Select service'"
        [compareWith]="compareReference"
        (selectionChange)="changeService($event)"
      >
        <mat-option *ngIf="availableServices.length >= 2" [value]="sessionService.allsReference">
          <span id="servAll">{{ "page.preference.all" | translate }}</span>
        </mat-option>
        <mat-option *ngFor="let service of availableServices; let i = index" [value]="service">
          <span attr.id="serv{{ i }}">{{ service.display }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <!-- ACTIONS -->
  <mat-dialog-actions style="justify-content: space-between">
    <button mat-button mat-dialog-close color="primary" cdkFocusInitial>
      {{ "common.cancel" | translate }}
    </button>
    <button mat-button mat-dialog-close (click)="close()">
      {{ "btn.ok" | translate }}
    </button>
  </mat-dialog-actions>
</div>
