import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataSourceBackendParam } from "src/app/models/data-sources-backend-param.interface";
import { Filter } from "src/app/models/filter.interface";
import { IKnowledgeBase, IKnowledgeInfo, IKnowledges, IKnowMedia, KNOW_DOC_CATEGORY } from "src/app/models/knowledge.interface";
import { Reference } from "src/app/models/sharedModels.model";
import { ApiService } from "./api.service";
import { BaseApiService } from "./baseApi.service";

@Injectable({
  providedIn: "root",
})
export class KnowledgeApiService extends BaseApiService {
  constructor(private api: ApiService) {
    super(
      "Knowledges", // Theme !
      ["dashboard/knowledge"], // Post route paths
      [
        "dashboard/knowledgeInfos", // 0
        "dashboard/nbKnowledges", // 1
        "dashboard/knowledge", // 2
        "dashboard/careplansByKnowledge", // 3
        "dashboard/knowledges/careplans", // 4
        "knowledge", // 5
        "dashboard/media", // 6
      ], // Get route paths
      ["dashboard/knowledge", "dashboard/publishKnowledge"], // Update route paths
      ["dashboard/knowledge"] // Delete route paths
    );
  }

  public findKnowledge(params: DataSourceBackendParam): Observable<IKnowledgeInfo[]> {
    return this.api.get(this.readRoutes[0], {
      sortId: params.sortId,
      sortOrder: params.sortOrder,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      filters: JSON.stringify(params.filters),
      search: params.search,
      includeShortDesc: params.includeShortDesc,
      servicesIds: params.services?.join(","),
    }) as Observable<IKnowledgeInfo[]>;
  }

  public getKnowledgeCount(
    search?: string,
    includeShortDesc?: boolean,
    filters?: Filter[],
    services?: string[]
  ): Observable<{ count: number }> {
    return this.api.get(this.readRoutes[1], {
      search,
      includeShortDesc,
      filters: JSON.stringify(filters),
      servicesIds: services?.join(","),
    }) as Observable<{ count: number }>;
  }

  public getKnowledgeDetails(identifier: string): Observable<{
    published: IKnowledgeBase;
    draft: IKnowledgeBase;
  }> {
    return this.api.get(this.readRoutes[2], { identifier }) as Observable<{
      published: IKnowledgeBase;
      draft: IKnowledgeBase;
    }>;
  }

  public update(knowledge: IKnowledgeBase): Observable<IKnowledgeBase> {
    return this.api.put(this.updateRoutes[0], knowledge) as Observable<IKnowledgeBase>;
  }

  public updateServices(knowledgeId: string, healthcareservices: Reference[]): Observable<boolean> {
    const body = {
      knowledgeId,
      healthcareservices,
    };
    return this.api.put("dashboard/knowledge/servicesLinks", body) as Observable<boolean>;
  }

  public publish(knowledgeId: string): Observable<IKnowledgeBase> {
    const body = { knowledgeId };
    return this.api.put(this.updateRoutes[1], body) as Observable<IKnowledgeBase>;
  }

  public create(knowledge: IKnowledgeBase): Observable<IKnowledgeBase> {
    return this.api.post(this.createRoutes[0], knowledge) as Observable<IKnowledgeBase>;
  }

  public getCareplansByKnowledge(
    knowledgeId: string,
    lang: string
  ): Observable<
    {
      reference: string;
      name: string;
    }[]
  > {
    return this.api.get(this.readRoutes[3], {
      knowledgeIdentifier: knowledgeId,
      lang,
    }) as Observable<
      {
        reference: string;
        name: string;
      }[]
    >;
  }

  public deleteDraft(id: string): Observable<unknown> {
    return this.api.deleteWithParams(this.deleteRoutes[0], { _id: id });
  }

  public getKnowledgesCareplans(
    patientId: string,
    docCategory: KNOW_DOC_CATEGORY,
    careplanId?: string,
    lang?: string
  ): Observable<{ reference: string; knowledges: IKnowledgeBase[] }[]> {
    return this.api.get(this.readRoutes[4], {
      patientId,
      docCategory,
      careplanId,
      lang,
    }) as Observable<{ reference: string; knowledges: IKnowledgeBase[] }[]>;
  }

  public getDrugsKnowledges(refs: string[]): Observable<IKnowledges[]> {
    return this.api.get(this.readRoutes[5], { reference: JSON.stringify(refs) }) as Observable<IKnowledges[]>;
  }

  public getMediaFromIdentifier(mediaId: string): Observable<IKnowMedia> {
    return this.api.get(this.readRoutes[6], { identifier: mediaId }) as Observable<IKnowMedia>;
  }
}
