import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, merge } from "rxjs";
import { first, map, takeUntil, tap } from "rxjs/operators";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "src/app/components/confirmation-dialog/confirmation-dialog.component";
import { HelpData } from "src/app/helpers/helpData";
import { DataType, Filter } from "src/app/models/filter.interface";
import { ContentTypeIcon, IContentType, IPatientDocInfo } from "src/app/models/patient-document.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { DocumentApiService } from "src/app/providers/api/document-api.service";
import { DocumentService } from "src/app/providers/document.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { UserService } from "src/app/providers/user.service";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { GlobalHelpDialogComponent } from "../global-help-dialog/global-help-dialog.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { DocumentModalComponent } from "./document-modal/document-modal.component";
import { DocumentsDataSource } from "./documents.datasource";

@Component({
  selector: "app-patient-documents",
  templateUrl: "./patient-documents.component.html",
  styleUrls: ["./patient-documents.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class PatientDocumentsComponent extends WidgetBaseComponent implements OnDestroy, AfterViewInit {
  public currentPatientUser: PatientUser;

  public scrollAfterDataInit = false;
  // defined the order of the column
  displayedColumns = ["name", "modified", "ownerDisplay", "docCategory", "action"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("input") importInput: ElementRef;

  public dataSource: DocumentsDataSource;
  public documentsCount: number;
  public contentTypeIcon = ContentTypeIcon;
  public dataTypeText = DataType.TEXT;
  public includeArchived$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public actions: WidgetActionConfig[] = [
    {
      type: "toggle",
      label: "btn.includeArchives",
      action: ($event): unknown => this.toggleArchive($event), // Toggle action
      toggleState$: this.includeArchived$, // Dynamic toggle state
    },
    {
      type: "button",
      icon: "cloud_upload", // Example icon for the upload action
      label: "btn.import",
      ariaLabel: "icon button with an upload icon",
      condition$: combineLatest([
        this.userService.isAuthorized("dashboard/document", "POST"),
        this.userService.isAuthorized("dashboard/document", "PUT"),
      ]).pipe(map(([canPost, canPut]) => canPost || canPut)),
      action: (): unknown => this.selectFile(),
    },
  ];
  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.currentPatientUser = pu;

      // reload documents when patientUser is updated
      if (this.sort && this.paginator) {
        this.paginator.firstPage();
        this.loadDocuments();
      }
    }
  }

  constructor(
    private documentService: DocumentService,
    private documentApiService: DocumentApiService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    public helpData: HelpData,
    private userService: UserService,
    protected responsiveService: ResponsiveService
  ) {
    super(responsiveService);
    this.dataSource = new DocumentsDataSource(this.documentApiService);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadDocuments()),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.loadDocuments();
  }

  public loadDocuments(): void {
    this.dataSource.loadData({
      patientId: this.currentPatientUser.user.caremateIdentifier,
      sortId: this.sort.active,
      sortOrder: this.sort.direction,
      pageNumber: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      filters: this.dataSource.getAllFilters(),
      includeArchived: this.includeArchived$.value,
    });
    this.documentApiService
      .getDocumentsCount(this.currentPatientUser.user.caremateIdentifier, this.includeArchived$.value, this.dataSource.getAllFilters())
      .pipe(first(), takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.documentsCount = result?.count;
      });
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
    this.loadDocuments();
  }

  public selectFile(): void {
    this.importInput.nativeElement.value = ""; // Otherwise, the same file can't be selected again.
    this.importInput.nativeElement.click();
  }

  public import(event: { target: { files: FileList } }): void {
    const files = event.target.files;
    if (files) {
      this.openDocumentModal(files);
    } else {
      throw new Error("no file selected");
    }
  }

  public documentClicked(name: string): void {
    this.openDocumentModal(undefined, name);
  }

  public async openDocumentModal(files: FileList, name?: string): Promise<void> {
    let shouldOpen = true;

    if (files && files[0]) {
      if (!this.checkFileType(files[0])) {
        this.snackBar.open(this.translateService.instant("page.document.errors.format"), "OK", { duration: 5000 });
        shouldOpen = false;
        return;
      }
      if (files[0].size > 5000000) {
        this.snackBar.open(this.translateService.instant("page.document.errors.toobig"), "OK", { duration: 5000 });
        shouldOpen = false;
        return;
      }
      shouldOpen = await this.continueIfExists(files[0]);
    }

    if (shouldOpen) {
      const dialog = this.dialog.open(DocumentModalComponent, {
        data: {
          file: files ? files[0] : undefined,
          patientUser: this.currentPatientUser,
          name,
        },
        minWidth: 400,
      });
      dialog.afterClosed().subscribe(() => {
        this.loadDocuments();
      });
    }
  }

  public download(doc: IPatientDocInfo): void {
    this.documentService.downloadDocument(doc.name, doc.modified, this.currentPatientUser.user.caremateIdentifier);
  }

  public checkFileType(file: File): boolean {
    return Object.values(IContentType).includes(file.type as IContentType);
  }

  public async continueIfExists(file: File): Promise<boolean> {
    const historic = await this.documentService.getDocHistoric(this.currentPatientUser.user.caremateIdentifier, file.name).toPromise();
    if (historic) {
      const dialog = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("page.document.warning.continue"),
          type: ConfirmationDialogType.CONFIRM,
        },
      });
      return dialog.afterClosed().toPromise();
    } else {
      return true;
    }
  }

  public archive(doc: IPatientDocInfo): void {
    this.documentService
      .archiveDocument(doc.name, doc.modified, this.currentPatientUser.user.caremateIdentifier)
      .pipe(first())
      .subscribe(() => {
        this.loadDocuments();
      });
  }

  public toggleArchive(event: MatSlideToggleChange): void {
    this.includeArchived$.next(event.checked);
    if (this.paginator.pageIndex === 0) {
      this.loadDocuments();
    } else {
      this.paginator.firstPage(); // this will trigger loadDocuments has well after going back to the first page
    }
  }

  public openDocumentHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.patientDocumentHelp },
      disableClose: true,
    });
  }
}
