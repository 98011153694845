<span #focus></span>
<mat-card class="widget" [ngClass]="{ 'big forcePaddingBottom': isBig }">
  <mat-toolbar color="primary">
    <p class="fs-16">{{ "alerts.title" | translate }}</p>
    <a mat-icon-button aria-label="icon button with a ? icon" (click)="openAlertHelp()">
      <mat-icon>help_outline</mat-icon>
    </a>
    <span class="spacerBetween"></span>
    <app-widget-actions
      *ngIf="actions?.length"
      [actions]="actions"
      [isMobile]="isMobile"
      (actionTriggered)="handleAction($event.action)"
    ></app-widget-actions>
  </mat-toolbar>
  <mat-card-content [ngClass]="{ hide: alerts.length === 0 }">
    <table mat-table matSort matSortDirection="desc" matSortActive="date" [dataSource]="dataSource">
      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.date" | translate }}
          <app-item-filter
            fieldName="{{ 'table.date' | translate }}"
            propertyName="date"
            [dataType]="dataTypeDate"
            [defaultValue]="getFilter('date')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.creation | date : "short" }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <ul class="no-bullet" *ngIf="element.ruleMeta.typeTarget === RULE_TYPE_TARGET.OBSERVATION">
            <li *ngFor="let valueComponent of element.valueComponents" class="nameAndValue">
              <span>{{ valueComponent | translateAlertName : element.observation : allDefinitions }}</span>
              <span>&nbsp;</span>
              <span>{{
                valueComponent.code.coding[0].code
                  | loincAndValueMeaning
                    : element.observation?.code.coding[0].code
                    : valueComponent.valueQuantity.value
                    : allDefinitions
                    : true
                    : true
              }}</span>
            </li>
          </ul>
          <ng-container *ngIf="element.ruleMeta.typeTarget === RULE_TYPE_TARGET.QUESTIONNAIRE">
            <ul class="no-bullet" *ngFor="let page of element.questionnaireResponse?.group.group">
              <li *ngFor="let question of page.question" class="nameAndValue">
                <span>{{ question.text }}</span>
                <span>{{ question.answer[0].valueCoding.display }}</span>
              </li>
            </ul>
          </ng-container>
          <ul class="no-bullet" *ngIf="element.ruleMeta.typeTarget === RULE_TYPE_TARGET.NO_DATA_TRANSMISSION">
            <li *ngFor="let item of $any(element.noDataTransmission) | keyvalue" class="nameAndValue">
              <span>{{ "alerts.noDataTransmission." + item.key | translate }}</span>
              <span>{{ item.value | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </td>
      </ng-container>

      <!-- resultsPractitionner Column -->
      <ng-container matColumnDef="resultsPractitionner">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.resultsPractitionner" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.rule | getResultsPractitionner | getTranslation : "-" }}
          <mat-icon
            color="primary"
            class="custom-icon-small bottom-align"
            style="cursor: pointer"
            [matTooltip]="'btn.moreInfo' | translate"
            *ngIf="('dashboard/media' | isAuthorized | async) === true && element.rule | hasResultsPractitionnerMedia"
            (click)="showMedia(element)"
          >
            info
          </mat-icon>
        </td>
      </ng-container>

      <!-- level Column -->
      <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.level" | translate }}
          <app-item-filter
            fieldName="{{ 'table.level' | translate }}"
            propertyName="level"
            [dataType]="dataTypeChoice"
            [defaultValue]="getFilter('level')"
            [data]="dataSource.data"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="custom-icon-small" [ngClass]="element | alertLevelClass">notifications</mat-icon>
        </td>
      </ng-container>

      <!-- origin Column -->
      <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef>
          {{ "table.origin" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            class="custom-icon-small"
            matTooltip="{{ 'tile.questionnaires' | translate }}"
            *ngIf="element.ruleMeta.typeTarget === RULE_TYPE_TARGET.QUESTIONNAIRE"
          >
            library_books
          </mat-icon>
          <mat-icon
            class="custom-icon-small"
            matTooltip="{{ 'observation.title' | translate }}"
            *ngIf="element.ruleMeta.typeTarget === RULE_TYPE_TARGET.OBSERVATION"
          >
            local_hospital
          </mat-icon>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          {{ "table.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <button
            *ngIf="!isAlreadySeen(element)"
            mat-icon-button
            matTooltip="{{ 'btn.setSeen' | translate }}"
            (click)="onDetails(element, false)"
            aria-label="icon button with a pencil icon"
          >
            <mat-icon class="custom-icon-small warn">add_task</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="{{ 'btn.details' | translate }}"
            (click)="onDetails(element, true)"
            aria-label="icon button with a loupe icon"
          >
            <mat-icon class="custom-icon-small">search</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Status (practitioner) Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.status" | translate }}
          <app-item-filter
            fieldName="{{ 'model.patient.status' | translate }}"
            propertyName="statusTranslateKey"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('statusTranslateKey')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.statusTranslateKey() | translate }}
        </td>
      </ng-container>

      <!-- Responsable Column -->
      <ng-container matColumnDef="incharge">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.responsable" | translate }}
          <app-item-filter
            fieldName="{{ 'table.responsable' | translate }}"
            propertyName="author.display"
            [dataType]="dataTypeText"
            [data]="dataSource.data"
            [defaultValue]="getFilter('author.display')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.author?.display || "-" }}
        </td>
      </ng-container>

      <!-- Last comment (practitioner) Column -->
      <ng-container matColumnDef="lastcomment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "table.lastComment" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastComment() || "-" }}
        </td>
      </ng-container>

      <!-- Status (patient) Column -->
      <ng-container matColumnDef="patientStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "alerts.patientStatus" | translate }}
          <app-item-filter
            fieldName="{{ 'alerts.patientStatus' | translate }}"
            propertyName="patientStatusTranslateKey"
            [dataType]="dataTypeChoice"
            [data]="dataSource.data"
            [defaultValue]="getFilter('patientStatusTranslateKey')"
            (applyFilter)="applyFilter($event)"
          >
          </app-item-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.patientStatusTranslateKey() | translate }}
        </td>
      </ng-container>

      <!-- Comment (patient) Column -->
      <ng-container matColumnDef="patientComment">
        <th mat-header-cell *matHeaderCellDef>
          {{ "alerts.patientComment" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.comment }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [ngClass]="{ hide: alerts.length === 0 }"
      #paginator
      [pageIndex]="0"
      [pageSize]="5"
      [pageSizeOptions]="isBig ? [5, 10, 15, 20] : [5]"
      [hidePageSize]="!isBig"
    >
    </mat-paginator>
  </mat-card-content>
  <div *ngIf="loading" class="loading">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <p class="text-center" *ngIf="alerts.length === 0 && !loading">
    {{ "widget.noData" | translate }}
  </p>
</mat-card>
