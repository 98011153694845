import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { ICareplan } from "src/app/models/careplans.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { Reference } from "src/app/models/reference.interface";
import { HealthcareserviceService } from "src/app/providers/healthcareservice.service";
import { OrganizationsService } from "src/app/providers/organizations.service";
import { SessionService } from "src/app/providers/session.service";
import { UserService } from "src/app/providers/user.service";
import { IGroup } from "../patient-careplans/patient-careplans.component";

export interface ICareplanDialogOption {
  patient: PatientUser;
  careplan: ICareplan;
  careplans?: IGroup[];
  includeBirthdateAndGenderForm?: boolean;
  registration?: boolean;
  healthcareService?: Reference;
}

@Component({
  selector: "app-org-service-selection-dialog",
  templateUrl: "./org-service-selection-dialog.component.html",
  styleUrls: ["./org-service-selection-dialog.component.scss"],
})
export class OrgServiceSelectionComponent implements OnInit {
  public isMonitoringUser: boolean;
  public availableMonitOrgs: Reference[];
  public availableMonitServices: Reference[];
  public currentMonitService: Reference;
  public currentOrganization: Reference;
  public currentService: Reference;
  public availableOrganizations: Reference[];
  public availableServices: Reference[];
  public currentMonitOrg: Reference;

  constructor(
    public dialogRef: MatDialogRef<OrgServiceSelectionComponent>,
    public sessionService: SessionService,
    public healthcareserviceService: HealthcareserviceService,
    public organizationsService: OrganizationsService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public dialogOption: ICareplanDialogOption
  ) {}

  ngOnInit(): void {
    this.isMonitoringUser = this.userService.isMonitoringUser;

    this.currentOrganization = this.sessionService.organization;
    this.currentService = this.sessionService.currentService;
    this.availableOrganizations = this.organizationsService.availableOrganizations().map((org) => org.asReference);
    this.availableServices = this.healthcareserviceService.availableServices().map((service) => service.asReference);

    // Monitoring
    this.currentMonitOrg = this.sessionService.currentMonitoringOrg;
    this.currentMonitService = this.sessionService.currentMonitoringService;
    this.availableMonitOrgs = this.organizationsService.availableMonitoringOrgs().map((monitOrg) => monitOrg.asReference);
    this.availableMonitServices = this.healthcareserviceService
      .availableMonitoringServices()
      .map((monitService) => monitService.asReference);
  }

  public close(): void {
    this.dialogRef.close({
      org: this.currentOrganization,
      service: this.currentService,
      monitOrg: this.currentMonitOrg,
      monitService: this.currentMonitService,
    });
  }

  public compareReference = (o1: Reference, o2: Reference): boolean => o1?.reference === o2?.reference;

  public changeOrganization(event: MatSelectChange): void {
    this.currentOrganization = event.value;
    this.availableServices = this.healthcareserviceService
      .filterServicesByOrgAndMonitoring(event.value?.reference, this.userService.allServices)
      .map((service) => service.asReference);
    this.currentService = this.availableServices?.length ? this.availableServices[0] : undefined;
  }

  public changeMonitoringOrganization(event: MatSelectChange): void {
    this.currentMonitOrg = event.value;
    this.availableMonitServices = this.healthcareserviceService
      .filterServicesByOrgAndMonitoring(this.currentMonitOrg.reference, this.userService.allMonitoringServices)
      .map((service) => service.asReference);
    this.currentMonitService = this.availableMonitServices?.length ? this.availableMonitServices[0] : undefined;
  }

  public changeService(event: MatSelectChange): void {
    this.currentService = event.value;
  }

  changeMonitoringService(event: MatSelectChange): void {
    this.currentMonitService = event.value;
  }
}
