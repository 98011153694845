import { Component, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { WidgetActionConfig } from "../../widget-actions/widget-actions.component";

@Component({
  template: "",
})
export abstract class WidgetBaseComponent implements OnDestroy {
  public isMobile: boolean;
  protected isMobile$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public abstract actions: WidgetActionConfig[];

  protected onDestroy$ = new Subject<void>();

  constructor(protected responsiveService: ResponsiveService) {
    this.responsiveService.isHandset$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.isMobile = value;
      this.isMobile$.next(this.isMobile);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public handleAction(action: (event?) => unknown, event?: unknown): void {
    if (action) {
      action(event);
    } else {
      console.warn(`Action "${action}" not implemented`);
    }
  }
}
