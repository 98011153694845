import { Component, ElementRef, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { HelpData } from "src/app/helpers/helpData";
import { Tools } from "src/app/helpers/tools";
import { DataType, Filter } from "src/app/models/filter.interface";
import { IObservationDefinition } from "src/app/models/observations.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { ALERT_STATUS, IRuleAlert } from "src/app/models/rule-alert.interface";
import { RuleAlert } from "src/app/models/rule-alert.model";
import { RESULT_TYPE, RULE_TYPE_TARGET, RuleDefinition } from "src/app/models/rule.interface";
import { ITranslation } from "src/app/models/translation.interface";
import { TranslateAlertNamePipe } from "src/app/pipes/sharedAlerts.pipe";
import { ExportService } from "src/app/providers/export.service";
import { KnowledgeService } from "src/app/providers/knowledge.service";
import { ObservationsService } from "src/app/providers/observations.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { RuleAlertService } from "src/app/providers/rule-alert.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { UserService } from "src/app/providers/user.service";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { KnowledgeMediaListModalComponent } from "../knowledge-media-list-modal/knowledge-media-list-modal.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { AlertDetailsComponent } from "./alert-details/alert-details.component";
import { AlertPreferenceComponent, IShowAlertLevel } from "./alert-preference/alert-preference.component";
import { AlertDataSource } from "./alerts-list-datasource";

@Pipe({ name: "getResultsPractitionner" })
export class GetResultsPractitionnerPipe implements PipeTransform {
  transform(ruleDef: RuleDefinition): ITranslation {
    const result = ruleDef.resultsPractitionner?.filter((r) => r.resultType != RESULT_TYPE.MEDIA)?.[0]?.value;
    return Tools.isDefined(result) ? result : "-";
  }
}

@Pipe({ name: "hasResultsPractitionnerMedia" })
export class HasResultsPractitionnerMediaPipe implements PipeTransform {
  transform(ruleDef: RuleDefinition): boolean {
    const result = ruleDef.resultsPractitionner?.filter((r) => r.resultType == RESULT_TYPE.MEDIA)?.[0]?.value;
    return Tools.isDefined(result);
  }
}
@Component({
  selector: "app-patient-alerts",
  templateUrl: "./patient-alerts.component.html",
  styleUrls: ["./patient-alerts.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class RuleAlertComponent extends WidgetBaseComponent implements OnInit, OnDestroy {
  private hasAlert$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isBig$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public actions: WidgetActionConfig[] = [
    {
      type: "button",
      icon: "download",
      label: "observation.export",
      ariaLabel: "icon button with a download icon",
      condition$: combineLatest([
        this.hasAlert$,
        this.userService.isAuthorized("exportAlertsForPatient", "GET"), // Vérifie l'autorisation
      ]).pipe(
        map(([hasAlerts, isAuthorized]) => hasAlerts && isAuthorized) // Combine les deux conditions
      ),
      action: (): unknown => this.onExport(),
    },
    {
      type: "button",
      icon: "settings",
      label: "btn.settings",
      ariaLabel: "icon button with a settings icon",
      action: (): unknown => this.onSettings(),
    },
    {
      type: "button",
      icon: "open_in_full",
      label: "btn.details",
      ariaLabel: "icon button with a expand icon",
      condition$: combineLatest([this.hasAlert$, this.responsiveService.isHandset$, this.isBig$]).pipe(
        map(([hasAlerts, isHandset, isBig]) => hasAlerts && !isHandset && !isBig) // Combine les deux conditions
      ),
      action: (): unknown => this.onPlus(),
    },
    {
      type: "button",
      icon: "close_fullscreen",
      label: "btn.reduce",
      ariaLabel: "icon button with a unexpand icon",
      condition$: this.isBig$,
      action: (): unknown => this.onPlus(),
    },
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<RuleAlert>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("focus") target: ElementRef;
  public scrollAfterDataInit = false;

  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.patientId = pu.user.caremateIdentifier;
      this.patientServiceId = pu.patient?.healthcareservice?.[0]?.reference;
      if (!this.dataSource) {
        this.dataSource = new AlertDataSource(this.translatorService, this.translateNamePipe, this.allDefinitions);
      }
      this.initDataAndAutoRefresh(this.patientId, this.preferenceService.getAutoRefreshWidget());
    }
  }
  public patientId: string;
  public alerts: RuleAlert[] = [];
  public allDefinitions: IObservationDefinition[] = [];
  public isBig = false;
  public displayedColumns: string[] = ["date", "status", "level", "origin", "resultsPractitionner", "name", "action"];
  public dataSource: AlertDataSource;
  private currentPageSize: number;
  public lastFilter: Filter;
  public patientServiceId: string;
  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public RULE_TYPE_TARGET = RULE_TYPE_TARGET;
  public loading = true;
  private refreshInterval;
  /** Subject that emits when the component has been destroyed. */
  private alertsList$: Subscription;
  private definitions$: Subscription;
  private preferencesList$: Subscription;
  private preferencesUpdate$: Subscription;
  private preferencesUpdate2$: Subscription;
  private paginator$: Subscription;
  private showAlerts: IShowAlertLevel = {
    levelOne: false,
    levelTwo: true,
    levelThree: true,
  };

  constructor(
    private alertService: RuleAlertService,
    private preferenceService: PreferenceService,
    private dialog: MatDialog,
    public helpData: HelpData,
    private sessionService: SessionService,
    private tileManager: TileManager,
    private translatorService: TranslateService,
    private observationsService: ObservationsService,
    private translateNamePipe: TranslateAlertNamePipe,
    private exportService: ExportService,
    protected responsiveService: ResponsiveService,
    private userService: UserService,
    private knowledgeService: KnowledgeService
  ) {
    super(responsiveService);
    this.isBig = TileManager.isBig(PatientWidgetName.ALERTS);
    this.isBig$.next(this.isBig);
  }

  ngOnInit(): void {
    if (!this.dataSource) {
      this.dataSource = new AlertDataSource(this.translatorService, this.translateNamePipe, this.allDefinitions);
    }
    this.sessionService.refreshServerTraductions.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      // the idea is to make the pipes realize there's something that changed without
      // restarting everything. For that we just need to 'change' one of the
      // pipe variables.
      if (this.allDefinitions) {
        // Cloning will make it appear as if this is a new object
        this.allDefinitions = Tools.clone(this.allDefinitions);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearAutoRefresh();
  }

  private initDataAndAutoRefresh(userId: string, _autoRefreshIntervalMs = 60000): void {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    // this.refreshInterval = setInterval(() => { this.loadData(userId); }, autoRefreshIntervalMs);
    this.loadAllData(userId);
  }

  public clearAutoRefresh(): void {
    clearInterval(this.refreshInterval);
  }

  private loadAllData(id: string): void {
    if (this.preferencesList$) {
      this.preferencesList$.unsubscribe();
    }
    this.preferencesList$ = this.preferenceService
      .list(PreferenceContext.PATIENT_ALERTS_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          this.isBig = this.sessionService.globalPref?.keepLayoutFromPatientPage
            ? parameters.isBig
            : TileManager.isBig(PatientWidgetName.ALERTS);
          this.isBig$.next(this.isBig);
          this.tileManager.updateList(PatientWidgetName.ALERTS, this.isBig);
          if (parameters.showAlertsLevel) {
            this.showAlerts = parameters.showAlertsLevel;
          }
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
        }
        this.computeColumn();
        this.loadAlerts(id);
      });
  }

  private loadAlerts(id: string): void {
    if (this.alertsList$) {
      this.alertsList$.unsubscribe();
    }
    const alertsLevelsFilter = [];
    if (this.showAlerts.levelOne) {
      alertsLevelsFilter.push("1");
    }
    if (this.showAlerts.levelTwo) {
      alertsLevelsFilter.push("2");
    }
    if (this.showAlerts.levelThree) {
      alertsLevelsFilter.push("3");
    }
    this.alertsList$ = this.alertService
      .listForPatient(id, alertsLevelsFilter.length ? alertsLevelsFilter : null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((ra) => {
        const filteredAlerts = ra.filter((a) => a.rule.idRule !== null);
        this.alerts = filteredAlerts;
        this.hasAlert$.next(this.alerts?.length ? true : false);
        this.definitions$?.unsubscribe();
        this.definitions$ = this.observationsService
          .listDef(this.patientId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((defs) => {
            this.allDefinitions = this.keepOnlyUsefulDefinitions(defs, this.alerts);
            this.dataSource.loadData(filteredAlerts);
            this.initPaginatorAndSort();
            if (this.scrollAfterDataInit && this.isBig && this.sessionService.globalPref?.keepLayoutFromPatientPage) {
              this.scroll();
            }
            this.loading = false;
          });
      });
  }

  private keepOnlyUsefulDefinitions(obsDef: IObservationDefinition[], alerts: RuleAlert[]): IObservationDefinition[] {
    const allAlertsLoincs: string[] = [];
    alerts.forEach((alert) => {
      alert.valueComponents.forEach((vc) => {
        allAlertsLoincs.push(vc.code.coding[0].code);
      });
    });
    const definitions: IObservationDefinition[] = obsDef.filter((d) => d.components.find((c) => allAlertsLoincs.includes(c.loinc)));
    return definitions;
  }

  public applyFilter(filter: Filter): void {
    this.lastFilter = filter;
    this.dataSource.setFilter(filter);
  }

  public onPlus(): void {
    this.isBig = !this.isBig;
    this.isBig$.next(this.isBig);
    this.tileManager.updateList(PatientWidgetName.ALERTS, this.isBig);
    this.computeColumn();
    this.updatePreference(true);
    this.scroll();
  }

  public updateLastFocus(): void {
    if (this.preferencesUpdate$) {
      this.preferencesUpdate$.unsubscribe();
    }
    this.preferencesUpdate$ = this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_PAGE,
        parameters: {
          lastFocusWidgetName: PatientWidgetName.ALERTS,
        } as PatientPageParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  public computeColumn(): void {
    if (this.isBig) {
      this.displayedColumns = [
        "date",
        "level",
        "resultsPractitionner",
        "origin",
        "name",
        "status",
        "incharge",
        "lastcomment",
        "patientStatus",
        "patientComment",
        "action",
      ];
    } else {
      this.displayedColumns = ["date", "status", "level", "resultsPractitionner", "origin", "name", "action"];
    }
  }

  public scroll(): void {
    if (this.target) {
      setTimeout(() => {
        this.target.nativeElement.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 100);
    }
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public initPaginatorAndSort(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    if (this.paginator$) {
      this.paginator$.unsubscribe();
    }
    this.paginator$ = this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference(false);
      }
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  /**
   * Preferences
   */
  public updatePreference(updateFocus: boolean): void {
    if (this.preferencesUpdate2$) {
      this.preferencesUpdate2$.unsubscribe();
    }
    this.preferencesUpdate2$ = this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_ALERTS_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          isBig: this.isBig,
          showAlertsLevel: this.showAlerts,
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.isBig && updateFocus) {
          this.updateLastFocus();
        }
      });
  }

  public onDetails(ruleAlert: RuleAlert, createAction: boolean): void {
    this.dialog
      .open(AlertDetailsComponent, {
        data: {
          alert: ruleAlert,
          createAction,
          patientServiceId: this.patientServiceId,
          allDefinitions: this.allDefinitions,
        },
        maxWidth: createAction ? "80vw" : "30vw",
        maxHeight: "93vh",
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.applyFilter(this.lastFilter));
  }

  public isAlreadySeen(ruleAlert: RuleAlert): boolean {
    return ruleAlert.alertStatus === ALERT_STATUS.PROCESSED;
  }

  public openAlertHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.patientAlertHelp },
      disableClose: true,
    });
  }

  public onSettings(): void {
    const dialogRef = this.dialog.open(AlertPreferenceComponent, {
      data: {
        showAlertLevels: this.showAlerts,
      },
      disableClose: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: IShowAlertLevel) => {
        if (result) {
          this.showAlerts = result;
          this.updatePreference(false);
          this.loadAlerts(this.patientId);
        }
      });
  }

  public onExport(): void {
    this.exportService.exportAlerts(this.patientId, this.sessionService.userLang);
  }

  public async showMedia(rule: IRuleAlert): Promise<void> {
    const mediaId = rule.rule.resultsPractitionner?.filter((r) => r.resultType == RESULT_TYPE.MEDIA)?.[0]?.value;
    const media = await this.knowledgeService.getMediaFromIdentifier(mediaId);
    if (media) {
      this.dialog.open(KnowledgeMediaListModalComponent, {
        data: {
          medias: [media],
        },
        minWidth: "50vw",
      });
    }
  }
}
