import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { first, skipWhile, takeUntil } from "rxjs/operators";
import { GlobalHelpDialogComponent } from "src/app/components/global-help-dialog/global-help-dialog.component";
import { FORMS_MODE } from "src/app/helpers/formsData";
import { HelpData } from "src/app/helpers/helpData";
import { Appointment } from "src/app/models/appointment.model";
import { DataType, Filter } from "src/app/models/filter.interface";
import { Notification } from "src/app/models/notifications.interface";
import { PatientUser } from "src/app/models/patient.interface";
import { PatientPageParameter, PatientWidgetName, PreferenceContext, WidgetPatientParameter } from "src/app/models/preference.interface";
import { AppointmentService } from "src/app/providers/appointment.service";
import { PreferenceService } from "src/app/providers/preference.service";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { SessionService } from "src/app/providers/session.service";
import { TileManager } from "src/app/providers/tile-manager.service";
import { UserService } from "src/app/providers/user.service";
import { AppointmentDelayDialogComponent } from "../appointment-delay-dialog/appointment-delay-dialog.component";
import { WidgetBaseComponent } from "../base/widget-base/widget-base.component";
import { ConfirmationDialogComponent, ConfirmationDialogType } from "../confirmation-dialog/confirmation-dialog.component";
import { Item } from "../item-selector/item-selector.component";
import { PatientTeleconsultationDialogComponent } from "../patient-teleconsultations-dialog/patient-teleconsultations-dialog.component";
import { TeleConsultationDialogComponent } from "../tele-consultation-dialog/tele-consultation-dialog.component";
import { WidgetActionConfig } from "../widget-actions/widget-actions.component";
import { TeleconsultationsListDataSource } from "./teleconsultations-list-datasource";

@Component({
  selector: "app-patient-teleconsultations",
  templateUrl: "./patient-teleconsultations.component.html",
  styleUrls: ["./patient-teleconsultations.component.scss", "../base/widget-base/widget-base.component.scss"],
})
export class PatientTeleconsultationsComponent extends WidgetBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public actions: WidgetActionConfig[] = [];
  @Input() set patientUser(pu: PatientUser) {
    if (pu?.user?.caremateIdentifier) {
      this.pu = pu;
      this.loadPreferences();
      setTimeout(() => {
        this.initDataAndAutoRefresh(
          pu.user.caremateIdentifier,
          this.isBig ? this.allTeleconsultations : false,
          this.preferenceService.getAutoRefreshWidget()
        );
      }, 500);
      if (this.sessionService.currentService && this.userService.ownOrganization) {
        this.setupServicesWatch();
      } else {
        this.sessionService.servicesSetupWatch
          .pipe(
            skipWhile(() => !this.userService.ownOrganization),
            first()
          )
          .subscribe(() => {
            this.setupServicesWatch();
          });
      }
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<Appointment>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("focus") target: ElementRef;
  public scrollAfterDataInit = false;
  public displayedColumns: string[] = ["startDate", "endDate", "object", "participant", "dateSeenByPatient", "action"];
  public visios: Appointment[] = [];
  public dataSource: TeleconsultationsListDataSource;
  public intakeDrugs: Notification[] = [];
  private currentPageSize: number;
  public sliderData: Item[] = [];
  public pu: PatientUser;
  public defaultFilterAlreadyApply = false;
  public isBig = false;
  public allTeleconsultations = false;
  public isAllServices = true;
  public isSameOrganization = false;
  public isSameService = false;
  public newAppointmentTooltip: string;

  /**
   * Filter
   */
  public dataTypeText = DataType.TEXT;
  public dataTypeDate = DataType.DATE;
  public dataTypeChoice = DataType.CHOICE;
  public globalSearchValue = "";
  public loading = true;
  public has2FA = false;
  public skip2FA = false;
  private refreshInterval;
  private serviceSub: Subscription;
  private serviceMonitSub: Subscription;
  private organizationSub: Subscription;
  /** Subject that emits when the component has been destroyed. */

  constructor(
    private appointmentService: AppointmentService,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public sessionService: SessionService,
    public helpData: HelpData,
    private tileManager: TileManager,
    protected responsiveService: ResponsiveService
  ) {
    super(responsiveService);
    this.sessionService.refreshTeleconsultationsDataList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.initDataAndAutoRefresh(
        this.pu.user.caremateIdentifier,
        this.isBig ? this.allTeleconsultations : false,
        this.preferenceService.getAutoRefreshWidget()
      );
    });
    this.isBig = TileManager.isBig(PatientWidgetName.TELECONSULTATIONS);
  }

  ngOnInit(): void {
    this.userService
      .account()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((account) => {
        this.has2FA = account.mfaActive;
      });
    this.dataSource = new TeleconsultationsListDataSource();
  }

  ngAfterViewInit(): void {
    this.initPaginatorAndSort();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearAutoRefresh();
  }

  private setupServicesWatch() {
    // This is a special case because, when its a monitoring user, we cannot
    // allow creation of teleconsultation when EITHER the normal services OR the
    // monitoring services are in "all" mode
    this.setupServices();
    this.serviceSub?.unsubscribe();
    this.serviceSub = this.sessionService.refreshCurrentService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.setupServices();
    });
    if (this.userService.isMonitoringUser) {
      this.serviceMonitSub?.unsubscribe();
      this.serviceMonitSub = this.sessionService.refreshCurrentMonitService.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.setupServices();
      });
    }
    this.organizationSub?.unsubscribe();
    this.organizationSub = this.sessionService.refreshOrganizationsList.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.setupServices();
    });
  }

  private setupServices() {
    if (this.sessionService.organization.reference !== this.pu?.patient?.managingOrganization?.reference) {
      this.isSameOrganization = false;
    } else if (this.sessionService.organization.reference === this.pu?.patient?.managingOrganization?.reference) {
      this.isSameOrganization = true;
    }

    if (
      this.pu.patient.healthcareservice.find((patientService) => patientService.reference === this.sessionService.currentService?.reference)
    ) {
      this.isSameService = true;
    } else {
      this.isSameService = false;
    }

    if (!this.sessionService.currentService || (this.userService.isMonitoringUser && !this.sessionService.currentMonitoringService)) {
      this.isAllServices = true;
      return;
    }
    if (this.userService.isMonitoringUser) {
      this.isAllServices = this.sessionService.isAllServices(true) || this.sessionService.isAllServices(false);
    } else {
      this.isAllServices = this.sessionService.isAllServices(false);
    }

    // Set tooltip for the "+" icon
    if (!this.isSameOrganization) {
      this.newAppointmentTooltip = this.translateService.instant("page.patient.notSameOrganizationError");
    } else if (this.isAllServices) {
      this.newAppointmentTooltip = this.translateService.instant("btn.disabledWithAll");
    } else if (!this.isSameService) {
      this.newAppointmentTooltip = this.translateService.instant("page.patient.notSameServiceError");
    } else {
      this.newAppointmentTooltip = "";
    }
  }

  private initDataAndAutoRefresh(userId: string, allTeleconsultations: boolean, _autoRefreshIntervalMs = 60000) {
    if (this.refreshInterval) {
      this.clearAutoRefresh();
    }
    // this.refreshInterval = setInterval(() => { this.initData(userId, allTeleconsultations); }, autoRefreshIntervalMs);
    this.initData(userId, allTeleconsultations);
  }

  private clearAutoRefresh() {
    clearInterval(this.refreshInterval);
  }

  private initData(userId: string, allTeleconsultations: boolean) {
    this.appointmentService
      .listVisioAppointements(userId, allTeleconsultations)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((obs) => {
        this.visios = obs;
        this.loadDataSource();
        if (!this.defaultFilterAlreadyApply) {
          this.sort.sort({
            id: "startDate",
            start: "desc",
            disableClear: false,
          });
          this.defaultFilterAlreadyApply = true;
        }
        this.loading = false;
      });
  }

  public initPaginatorAndSort(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;

    // Detect page size change
    this.currentPageSize = this.paginator.pageSize;
    this.paginator.page.pipe(takeUntil(this.onDestroy$)).subscribe((page) => {
      if (this.currentPageSize !== page.pageSize) {
        this.updatePreference(false);
      }
      this.currentPageSize = this.paginator.pageSize;
    });
  }

  public loadDataSource(): void {
    this.dataSource.loadData(this.visios);
  }

  /**
   * Filter
   */
  public getFilter(propertyName: string): Filter {
    return this.dataSource.getFilter(propertyName);
  }

  public applyFilter(filter: Filter): void {
    this.dataSource.setFilter(filter);
  }

  public clearFilter(): void {
    this.globalSearchValue = "";
    this.dataSource.clearFilter();
  }

  /**
   * Preferences
   */
  public updatePreference(updateFocus: boolean): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_TELECONSULTATIONS_LIST,
        parameters: {
          filters: this.dataSource.getAllFilters(),
          isBig: this.isBig,
          allTeleconsultations: this.allTeleconsultations,
        } as WidgetPatientParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (updateFocus) {
          this.updateLastFocus();
        }
      });
  }

  private loadPreferences() {
    this.preferenceService
      .list(PreferenceContext.PATIENT_TELECONSULTATIONS_LIST)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((parameters: WidgetPatientParameter) => {
        if (parameters) {
          // Apply saved filters
          parameters.filters.forEach((filter: Filter) => {
            this.applyFilter(filter);
          });
          this.allTeleconsultations = parameters.allTeleconsultations;
          this.isBig = this.sessionService.globalPref?.keepLayoutFromPatientPage
            ? parameters.isBig
            : TileManager.isBig(PatientWidgetName.TELECONSULTATIONS);
          this.tileManager.updateList(PatientWidgetName.TELECONSULTATIONS, this.isBig);
        }

        if (this.scrollAfterDataInit && this.sessionService.globalPref?.keepLayoutFromPatientPage) {
          this.scroll();
        }
      });
  }

  public onStartVideoCall(appointment: Appointment): void {
    if (!this.has2FA && !this.skip2FA) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      appointment,
    };
    this.dialog.open(TeleConsultationDialogComponent, dialogConfig);
  }

  public onEditVisio(appointment: Appointment): void {
    this.dialog.open(PatientTeleconsultationDialogComponent, {
      data: {
        appointment,
        patientUser: this.pu,
        mode: FORMS_MODE.UPDATE,
        fromPatientWidget: true,
      },
    });
  }

  public onAddVisio(): void {
    this.dialog
      .open(PatientTeleconsultationDialogComponent, {
        data: {
          appointment: null,
          patientUser: this.pu,
          mode: FORMS_MODE.CREATE,
          fromPatientWidget: true,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.initDataAndAutoRefresh(
          this.pu.user.caremateIdentifier,
          this.isBig ? this.allTeleconsultations : false,
          this.preferenceService.getAutoRefreshWidget()
        );
      });
  }

  public onDeleteVisio(appointment: Appointment): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          message: this.translateService.instant("common.removeForEverAsk"),
          type: ConfirmationDialogType.CONFIRM,
        },
      })
      .afterClosed()
      .subscribe((yes) => {
        if (yes) {
          this.appointmentService.deleteApp(appointment).subscribe(() => {
            this.initDataAndAutoRefresh(
              this.pu.user.caremateIdentifier,
              this.isBig ? this.allTeleconsultations : false,
              this.preferenceService.getAutoRefreshWidget()
            );
            const msg = this.translateService.instant("common.success");
            this.snackBar.open(msg, "ok", { duration: 3000 });
          });
        }
      });
  }

  public openPTeleconsulationHelp(): void {
    this.dialog.open(GlobalHelpDialogComponent, {
      data: { slides: this.helpData.patientTeleconsultationsHelp },
      disableClose: true,
    });
  }

  /**
   * TODO : NONE BIG MODE YET
   */
  public updateLastFocus(): void {
    this.preferenceService
      .update({
        context: PreferenceContext.PATIENT_PAGE,
        parameters: {
          lastFocusWidgetName: PatientWidgetName.TELECONSULTATIONS,
        } as PatientPageParameter,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
  }

  public scroll(): void {
    if (this.target) {
      setTimeout(() => {
        this.target.nativeElement.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }

  public onPlus(): void {
    this.isBig = !this.isBig;
    this.tileManager.updateList(PatientWidgetName.TELECONSULTATIONS, this.isBig);
    this.updatePreference(true);
    this.initDataAndAutoRefresh(
      this.pu.user.caremateIdentifier,
      this.isBig ? this.allTeleconsultations : false,
      this.preferenceService.getAutoRefreshWidget()
    );
    this.scroll();
  }

  public refreshData(): void {
    this.updatePreference(false);
    this.initDataAndAutoRefresh(
      this.pu.user.caremateIdentifier,
      this.isBig ? this.allTeleconsultations : false,
      this.preferenceService.getAutoRefreshWidget()
    );
  }

  public updateAppointmentWithDelay(appointment: Appointment): void {
    this.dialog
      .open(AppointmentDelayDialogComponent, {
        data: {
          appointment: appointment,
        },
      })
      .afterClosed()
      .subscribe((minutesDelay) => {
        if (typeof minutesDelay === "number") {
          appointment.minutesDelay = minutesDelay;
          this.appointmentService.updateVisioAppointement(appointment).subscribe(() => {
            this.snackBar.open(`✅ ${this.translateService.instant("common.success")}`, undefined, {
              duration: 3000,
            });
          });
        }
      });
  }
}
