import { TranslateService } from "@ngx-translate/core";
import { Tools } from "../helpers/tools";
import { Activity, CareplanAccess, Goal, IAddresses, ICareplan, IOrder } from "./careplans.interface";
import { Codes } from "./codes.interface";
import { Identifier } from "./identifier.interface";
import { Period } from "./period.interface";
import { Reference } from "./reference.interface";
import { IPeriod, ITiming, SCHEDULE_PERIOD } from "./sharedInterfaces";

/**
 * Helper class for Careplan
 */
export class Careplan implements ICareplan {
  identifier: Identifier[];
  support: Reference[];
  status: string;
  period: Period;
  description: string;
  resourceType: string;
  participant: Reference[];
  activity: Activity[];
  category: Codes[];
  subject: Reference;
  author: Reference[];
  // Optional :
  goal?: Goal[];
  context?: Reference;
  note?: string[];
  addresses?: IAddresses[];
  actionResulting?: IOrder[];
  access?: CareplanAccess;

  constructor(careplan: ICareplan) {
    this.identifier = careplan.identifier;
    this.support = careplan.support;
    this.status = careplan.status;
    this.period = careplan.period;
    this.description = careplan.description;
    this.resourceType = careplan.resourceType;
    this.participant = careplan.participant;
    this.activity = careplan.activity;
    this.category = careplan.category;
    this.subject = careplan.subject;
    this.author = careplan.author;
    // Optional :
    if (Tools.isDefined(careplan.goal)) this.goal = careplan.goal;
    if (Tools.isDefined(careplan.context)) this.context = careplan.context;
    if (Tools.isDefined(careplan.note)) this.note = careplan.note;
    if (Tools.isDefined(careplan.addresses)) this.addresses = careplan.addresses;
    if (Tools.isDefined(careplan.actionResulting)) this.actionResulting = careplan.actionResulting;
    if (Tools.isDefined(careplan.access)) this.access = careplan.access;
  }

  // careplan status
  public static get CAREPLAN_STATUS_ACTIVE(): string {
    return "active";
  }
  public static get CAREPLAN_STATUS_INACTIVE(): string {
    return "inactive";
  }
  public static get CAREPLAN_STATUS_DRAFT(): string {
    return "draft";
  }
  public static get CAREPLAN_STATUS_ARCHIVE(): string {
    return "archive";
  }
  // activity status field
  public static get ACTIVITY_STATUS_ACTIVE(): string {
    return "active";
  }
  public static get ACTIVITY_STATUS_INACTIVE(): string {
    return "inactive";
  }
  public static get ACTIVITY_STATUS_ERROR(): string {
    return "error";
  }
  // careplan context
  public static get CONTEXT_CHU_LG(): string {
    return "CAREPLANCHULG";
  }
  public static get CONTEXT_CHU_SP(): string {
    return "CAREPLANCHUSP";
  }
  public static get CONTEXT_PATIENT(): string {
    return "CAREPLANPATIENT";
  }
  public static get NO_END_DATE(): string {
    return "9999-12-31";
  }

  public static getBoundsPeriod(activity: Activity): IPeriod {
    if (activity.detail.scheduledPeriod) {
      return activity.detail.scheduledPeriod;
    }
    return activity.detail.scheduledTiming.repeat.boundsPeriod;
  }

  public static getScheduledPeriod(timing: ITiming): SCHEDULE_PERIOD {
    if (!timing || !timing.periodUnits) {
      return SCHEDULE_PERIOD.DAY;
    }

    switch (timing.periodUnits.toLowerCase()) {
      case "min":
        return SCHEDULE_PERIOD.MINUTE;
      case "h":
        return SCHEDULE_PERIOD.HOUR;
      case "d":
        return SCHEDULE_PERIOD.DAY;
      case "w":
      case "wk":
        return SCHEDULE_PERIOD.WEEK;
      case "m":
        return SCHEDULE_PERIOD.MONTH;
      case "y":
        return SCHEDULE_PERIOD.YEAR;
      default:
        return SCHEDULE_PERIOD.DAY;
    }
  }

  public static setProtocol(activity: Activity, protocol: Reference): void {
    activity.detail.productReference.reference = protocol.reference;
    activity.detail.productReference.display = protocol.display;
    activity.detail.productReference.type = protocol.type ? protocol.type : null;
  }

  public static setRoute(activity: Activity, route: Reference): void {
    activity.detail.routeReference.reference = route.reference;
    activity.detail.routeReference.display = route.display;
    activity.detail.routeReference.type = route.type ? route.type : null;
  }

  public static setAdministration(activity: Activity, administration: Reference): void {
    activity.detail.administrationReference.reference = administration.reference;
    activity.detail.administrationReference.display = administration.display;
    activity.detail.administrationReference.type = administration.type ? administration.type : null;
  }

  public static setDiluent(activity: Activity, diluent: Reference): void {
    activity.detail.diluentReference.reference = diluent.reference;
    activity.detail.diluentReference.display = diluent.display;
    activity.detail.diluentReference.type = diluent.type ? diluent.type : null;
  }

  public static getTimingDescription(activity: Activity, translateService: TranslateService): string {
    if (!activity || !activity.detail.scheduledTiming) {
      return "";
    }

    let description = "";
    const period = Careplan.getScheduledPeriod(activity.detail.scheduledTiming.repeat);
    // frequence is not used anymore (replaced by timingCode or ITiming.when)
    if (period !== SCHEDULE_PERIOD.WEEK && period !== SCHEDULE_PERIOD.HOUR) {
      description += translateService.instant("schedule.every") + " ";
    } else {
      description += translateService.instant("schedule.every2") + " ";
    }

    // do not add "period" if it's only "1"
    if (activity.detail.scheduledTiming.repeat.period > 1) {
      description += activity.detail.scheduledTiming.repeat.period + " ";
    }

    // add time unit
    switch (period) {
      case SCHEDULE_PERIOD.MINUTE:
        description += translateService.instant("periodUnit.minute.plural");
        break;
      case SCHEDULE_PERIOD.HOUR:
        description += translateService.instant("periodUnit.hour.plural");
        break;
      case SCHEDULE_PERIOD.DAY:
        description += translateService.instant("periodUnit.day.plural");
        break;
      case SCHEDULE_PERIOD.WEEK:
        description += translateService.instant("periodUnit.week.plural");
        break;
      case SCHEDULE_PERIOD.MONTH:
        description += translateService.instant("periodUnit.month.plural");
        break;
      case SCHEDULE_PERIOD.YEAR:
        description += translateService.instant("periodUnit.year.plural");
        break;
    }
    if (activity.detail.scheduledTiming.repeat.count) {
      description += " (max " + activity.detail.scheduledTiming.repeat.count + "x)";
    }
    return description;
  }

  public static hasNoEndDate(careplan: ICareplan): boolean {
    return careplan?.period?.end?.[0] === "9";
  }
}
