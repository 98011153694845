import { CdkDragDrop, CdkDropList } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IWidgetComponent, WidgetName } from "src/app/models/widget.interface";
import { ResponsiveService } from "src/app/providers/responsive.service";
import { WidgetItem } from "../widget-item";
import { WidgetDirective } from "../widget.directive";

@Component({
  selector: "app-widget-manager",
  templateUrl: "./widget-manager.component.html",
  styleUrls: ["./widget-manager.component.scss"],
})
export class WidgetManagerComponent implements AfterViewInit, OnDestroy {
  @Input() column = 1;
  @Output() widgetMoved = new EventEmitter<WidgetItem[]>();
  @ViewChildren(WidgetDirective) widgetContainers: QueryList<WidgetDirective>;
  @ViewChildren(CdkDropList) dropLists: QueryList<CdkDropList>;

  private localWidgets: WidgetItem[];
  private widgetComponentRefs: ComponentRef<IWidgetComponent>[] = [];
  /** Subject that emits when the component has been destroyed. */
  private onDestroy$ = new Subject<void>();
  public isMobile: boolean;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private responsiveService: ResponsiveService
  ) {
    this.responsiveService.isHandset$.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.isMobile = value;
    });
  }

  @Input() set widgets(widgets: WidgetItem[]) {
    this.localWidgets = widgets?.filter((w) => w.name !== WidgetName.PATIENT_LIST);
    this.loadComponent();
  }

  get widgets(): WidgetItem[] {
    return this.localWidgets;
  }

  get columnArray(): unknown[] {
    const columnArray = [];
    for (let i = 0; i < this.column; i++) {
      columnArray.push(i);
    }
    return columnArray;
  }

  ngAfterViewInit(): void {
    this.widgetContainers.changes.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.loadComponent();
    });
    this.loadComponent();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadComponent() {
    if (!this.widgets || !this.widgetContainers) {
      return;
    }

    this.widgetContainers.forEach((wd) => wd.viewContainerRef.clear());
    this.widgetComponentRefs = [];

    this.widgets
      .sort((a, b) => a.row - b.row)
      .filter((w) => w.component)
      .forEach((widget) => this.appendWidget(widget));
    this.cdr.detectChanges();
  }

  public drop(event: CdkDragDrop<IWidgetComponent[]>): void {
    // Find the corresponding component from DOM element
    const componentDropped = this.widgetComponentRefs.find((ref) => {
      const refChildren = (ref.location.nativeElement as HTMLElement).children;
      if (refChildren && refChildren.length > 0) {
        return refChildren[0] === event.item.element.nativeElement;
      }
      return false;
    });

    if (componentDropped) {
      // Corresponding widget item
      const widgetIndex = this.widgets.findIndex((w) => w.component === componentDropped.componentType);
      if (this.widgets[widgetIndex]) {
        // Find the column
        const columnIndex = this.dropLists.toArray().findIndex((dropList) => dropList === event.container);
        const changeColumn = columnIndex !== this.widgets[widgetIndex].column;

        // Move widget
        const widgetContainersArray = this.widgetContainers.toArray();
        const destViewContainerRef = widgetContainersArray[columnIndex].viewContainerRef;
        if (changeColumn) {
          const fromViewContainerRef = widgetContainersArray[this.widgets[widgetIndex].column].viewContainerRef;
          const fromWidgetIndex = fromViewContainerRef.indexOf(componentDropped.hostView);
          fromViewContainerRef.detach(fromWidgetIndex);
          destViewContainerRef.insert(componentDropped.hostView);

          // Update CDK
          const fromDropList = this.dropLists.toArray()[this.widgets[widgetIndex].column];
          fromDropList.removeItem(componentDropped.instance.cdkDrag);
          const destDropList = this.dropLists.toArray()[columnIndex];
          destDropList.addItem(componentDropped.instance.cdkDrag);
        }

        const insertPosition = destViewContainerRef.length > event.currentIndex ? event.currentIndex : destViewContainerRef.length - 1;
        destViewContainerRef.move(componentDropped.hostView, insertPosition);

        // Update widget column position
        this.widgets[widgetIndex].column = columnIndex;

        // Update widget row position
        const widgetsInSameColumn = this.widgets.filter(
          (w) => w.column === this.widgets[widgetIndex].column && w.name !== this.widgets[widgetIndex].name
        );
        if (widgetsInSameColumn.findIndex((w) => w.row === insertPosition) !== -1) {
          const previousIndex = this.widgets[widgetIndex].row;
          // there is a widget in this position
          widgetsInSameColumn.forEach((wColumn) => {
            if (wColumn.row >= insertPosition && (wColumn.row < previousIndex || changeColumn)) {
              const index = this.widgets.findIndex((w) => w.name === wColumn.name);
              this.widgets[index].row++;
            }
            if (wColumn.row <= insertPosition && wColumn.row > previousIndex && !changeColumn) {
              const index = this.widgets.findIndex((w) => w.name === wColumn.name);
              this.widgets[index].row--;
            }
          });
        }
        this.widgets[widgetIndex].row = insertPosition;

        this.cdr.detectChanges();

        this.widgetMoved.emit(this.widgets);
      }
    }
  }

  private appendWidget(widget: WidgetItem) {
    const widgetContainersArray = this.widgetContainers.toArray();
    const columnIndex = widget.column < this.widgetContainers.length ? widget.column : 0;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(widget.component);
    if (widgetContainersArray.length > columnIndex) {
      const viewContainerRef = widgetContainersArray[columnIndex].viewContainerRef;
      const insertPosition = viewContainerRef.length > widget.row ? widget.row : undefined;
      const componentRef = viewContainerRef.createComponent(componentFactory, insertPosition);
      this.widgetComponentRefs.push(componentRef);
    }
    this.cdr.detectChanges();
  }
}
